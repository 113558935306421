import React, {useCallback, useEffect} from 'react';
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode} from '../../helpers/common';
import {CODE_TYPE} from '../../constants/common';
import {CopyField, HyperLinkField} from '../paymentsForm/templateOne/controlsType/controlsType';

/* Styled Components */
import {
  StyledCodeBorderWrapped,
  StyledCodeLabel,
  StyledCodeWrapper,
  StyledHistoryDetailsDataWrapper,
  StyledItemValue,
  StyledItemLabel
} from '../paymentsHistory/templateOne/web/historyWeb.styled';
import {StyledFlexContainerColumn} from '../paymentsForm/templateOne/web/formWeb.styled';

function GenerateCode({
  codeDetailsData,
  translations,
  setDataName,
  alertContext,
  currency,
  isCurrencySymbol
}) {
  const {t} = useTranslation();
  useEffect(() => {
    const filteredCodes = codeDetailsData.filter(
      ({dataName}) => dataName === CODE_TYPE.BAR_CODE || dataName === CODE_TYPE.QR_CODE
    );

    if (filteredCodes?.length) {
      setDataName(filteredCodes[0].dataName);
    }
  }, [codeDetailsData, setDataName]);

  /**
   * A callback function to handle opening an alert with a success message.
   * @callback openAlertHandler
   * @returns {void} This function does not return anything.
   */
  const openAlertHandler = useCallback(() => {
    alertContext?.success(t(('copyMessage').toLowerCase()));
  }, [alertContext, t]);

  /**
   * Renders a component based on the provided data.
   * If dataName is 'BarCode' and autoGenerate is true, renders a Barcode component wrapped in StyledCodeWrapper.
   * If dataName is 'BarCode' and autoGenerate is false, renders an image with the source set to data.
   * If dataName is 'QRCode' and autoGenerate is true, renders a QRCode component wrapped in StyledCodeWrapper.
   * If dataName is 'QRCode' and autoGenerate is false, renders an image with the source set to data.
   * If dataName is neither 'BarCode' nor 'QRCode', renders a StyledLabelInfoForDynamicLabel with the provided data.
   *
   * @param {string} dataName - The type of data ('BarCode' or 'QRCode').
   * @param {string} copyDataName - The data translation key.
   * @param {boolean} autoGenerate - Indicates whether to generate the code automatically.
   * @param {string} data - The data to be rendered (value for Barcode/QRCode or image source).
   * @param {boolean} hasCopy - Indicates if data is copiable.
   * @returns {React.ReactNode} The rendered component.
   */
  const renderComponent = ({
    dataName,
    autoGenerate,
    data,
    hasCopy,
    hasLink
  }) => {
    const isBarCode = dataName === CODE_TYPE.BAR_CODE;
    const isQRCode = dataName === CODE_TYPE.QR_CODE;
    const amount = dataName === CODE_TYPE.AMOUNT;

    if (isBarCode || isQRCode) {
      const CodeComponent = isBarCode ? Barcode : QRCode;
      const altText = isBarCode ? 'Barcode' : 'QRCode';

      return (
        <>
          <StyledCodeLabel>{t(('scanToPay').toLowerCase())}</StyledCodeLabel>
          <StyledCodeBorderWrapped codeType={dataName}>
            <StyledCodeWrapper codeType={dataName}>
              {autoGenerate ? (
                <CodeComponent value={data} />
              ) : (
                <img src={data} alt={altText} />
              )}
            </StyledCodeWrapper>
          </StyledCodeBorderWrapped>
          {data && hasLink ? <HyperLinkField href={data} /> : null}
        </>
      );
    }

    return hasCopy ? (
      <CopyField
        copyValue={data}
        dataName={dataName}
        alertHandler={openAlertHandler}
      />
    ) : (
      <StyledItemValue
        descriptionValue={dataName === CODE_TYPE.DESCRIPTION}
        textCenter
      >
        {amount ? Number(data)?.toFixed(2) : data}
        {' '}
        {amount ? getCurrencySymbolOrCode(currency, isCurrencySymbol) : null}
      </StyledItemValue>
    );
  };

  const orderedData = codeDetailsData.sort((a, b) => a.order - b.order);

  return (
    <StyledFlexContainerColumn>
      {orderedData?.map(({
        dataName, copyDataName, autoGenerate, data, hasCopy, hasLink, dataType
      }) => (
        <>
          <StyledHistoryDetailsDataWrapper
            key={dataName}
            isGeneratedCode={
              dataName === CODE_TYPE.BAR_CODE || dataName === CODE_TYPE.QR_CODE
            }
          >
            {/* This condition should be removed after centrivo changes */}
            {dataType !== 14 ? (
              <>
                {dataName === CODE_TYPE.BAR_CODE || dataName === CODE_TYPE.QR_CODE || dataName === CODE_TYPE.DESCRIPTION || hasCopy ? null : (
                  <StyledItemLabel>
                    {translations[dataName?.toLowerCase()] ?? dataName}
                  </StyledItemLabel>
                )}
                {renderComponent({
                  dataName,
                  copyDataName,
                  autoGenerate,
                  data,
                  hasCopy,
                  hasLink
                })}
              </>
            ) : null }
          </StyledHistoryDetailsDataWrapper>

          {/* This condition should be removed after centrivo changes */}
          {data && dataType === 14 ? <HyperLinkField href={data} /> : null}
        </>
      ))}
    </StyledFlexContainerColumn>
  );
}

GenerateCode.defaultProps = {
  codeDetailsData: null,
  translations: null
};

GenerateCode.propTypes = {
  codeDetailsData: PropTypes.array,
  translations: PropTypes.object,
  setDataName: PropTypes.func.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired
};
export default GenerateCode;
