import instance from './apiService';
import Config from '../configs/mainConfig';
import {BACK_TO_FORM_BUTTON_CONTROL, PAYMENTS_IDS} from '../constants/common';

/**
 * Retrieves payment system controls for a specific site, payment system, transaction type, and control step.
 *
 * @param {function} setSitePaymentSystemControls - A function to set the retrieved payment system controls.
 * @param {number} siteId - The numeric identifier of the site.
 * @param {number} paymentSystemId - The identifier of the payment system.
 * @param {number} operationType - The type of transaction.
 * @param {number} controlStep - The step in the control process.
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 *
 * @returns {Promise} - A promise that resolves with the retrieved payment system controls
 *                    or rejects with an error message.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setSitePaymentSystemControls = (controls) => {
 *   // Update UI with retrieved payment system controls
 * };
 *
 * const siteId = 123;
 * const paymentSystemId = 456;
 * const operationType = 1;
 * const controlStep = 1;
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   }
 * };
 *
 * getSitePaymentSystemControls(
 *      setSitePaymentSystemControls,
 *      siteId,
 *      paymentSystemId,
 *      operationType,
 *      controlStep,
 *      setIsLoading,
 *      alertContext
 *   )
 *   .then((controls) => {
 *     // Handle retrieved payment system controls
 *   })
 *   .catch((error) => {
 *     console.error('Failed to retrieve payment system controls:', error.message);
 *   });
 */
export const getSitePaymentSystemControls = (
  setSitePaymentSystemControls,
  siteId,
  paymentSystemId,
  operationType,
  controlStep,
  setIsLoading,
  alertContext
) => {
  setIsLoading(true);
  return instance.get(
    `${Config.apiURLs.getSitePaymentSystemControls}/${siteId}/${paymentSystemId}/${operationType}/${controlStep}`,
    {
      param: {
        siteId,
        paymentSystemId,
        operationType,
        controlStep
      }
    }
  )
    .then((response) => {
      if (response?.data && PAYMENTS_IDS.SAFETY_PAY_IDS.indexOf(paymentSystemId) !== -1) {
        response.data?.fields?.push(BACK_TO_FORM_BUTTON_CONTROL);
      }
      setSitePaymentSystemControls(response.data);
    })
    .catch((error) => alertContext.error(error.response.data))
    .finally(() => setIsLoading(false));
};
