import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledTabOneButton,
  StyledTabOneButtonsWrapper,
  StyledTabWebOneWrapper
} from './navigationTabsWebOne.styled';

function NavigationTabsWebOne({
  handleTabClick,
  activeTab
}) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <StyledTabWebOneWrapper>
      <StyledTabOneButtonsWrapper>
        <StyledTabOneButton
          type="button"
          name={OPERATION_TYPE_NUMBER.DEPOSIT}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
        >
          {theme.navigationVersionOneGeneralHasIcon ? (
            <TabDeposit />
          ) : null}
          {t(('deposit')?.toLowerCase())}
        </StyledTabOneButton>
        <StyledTabOneButton
          type="button"
          name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
        >
          {theme.navigationVersionOneGeneralHasIcon ? (
            <TabWithdrawal />
          ) : null}
          {t(('withdrawal')?.toLowerCase())}
        </StyledTabOneButton>
      </StyledTabOneButtonsWrapper>
    </StyledTabWebOneWrapper>
  );
}

NavigationTabsWebOne.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default NavigationTabsWebOne;
