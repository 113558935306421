import instance from './apiService';
import Config from '../configs/mainConfig';

export const login = ({siteId, userId}, setIsAuth) => {
  instance.post(Config.apiURLs.authenticate, {siteId, userId: userId.toString()})
    .then((response) => {
      localStorage.setItem('authToken', response.token);
      setIsAuth(true);
    })
    .catch((error) => error);
};
export const loginOnOldPlat = ({
  siteId, userId, lang, token
}, setIsLoading, setIsAuth) => {
  setIsLoading(true);
  instance.post(Config.apiURLs.oldPlatAuthenticate, {
    siteId,
    userId: userId.toString(),
    languageISO: lang,
    token
  })
    .then((response) => {
      localStorage.setItem('authToken', response.token);
      setIsAuth(true);
    })
    .catch((error) => error)
    .finally(() => setIsLoading(false));
};
