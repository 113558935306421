import React from 'react';

/* Styled Components*/
import StyledLoaderOverlay, {} from './loader.styled';

/* Assets */
import loader from '../../assets/images/loader.gif';

const Loader = function() {
  const loaderStates = true;

  return (
    loaderStates ? (
      <StyledLoaderOverlay>
        <img src={loader} alt="Loader" />
      </StyledLoaderOverlay>
    ) : null
  );
};

export default Loader;
