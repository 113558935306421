import React from 'react';
import PropTypes from 'prop-types';
import {navigationTabsComponents} from '../../../configs/mainConfig';

function NavigationTabsMobile({
  handleTabClick,
  activeTab,
  deviceType,
  tabVersion,
  isVisible
}) {
  const DynamicComponent = navigationTabsComponents?.[deviceType]?.[tabVersion];
  return (
    <DynamicComponent
      handleTabClick={handleTabClick}
      activeTab={activeTab.toString()}
      isVisible={isVisible}
    />
  );
}

NavigationTabsMobile.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.number.isRequired,
  deviceType: PropTypes.number.isRequired,
  tabVersion: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default NavigationTabsMobile;
