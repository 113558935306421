import styled, {css} from 'styled-components';

const StyledModalOverlay = styled.main`
  position: ${({theme}) => (theme.adminWrapper ? 'absolute' : 'fixed')};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => `${theme.overlayBackgroundColor}70`};
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
  z-index: ${({theme}) => theme.zIndex99999};

  ${(props) => props.showInfo && css`
    opacity: 1;
    visibility: visible;
  `}
`;

const StyledModalBody = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.generalCardBackgroundColor};
  transform: translateY(100%); /* Initial position off-screen at the bottom */
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  border-radius: ${({theme}) => `
    ${theme.cardCornerRadiusTopLeft || 0}px
    ${theme.cardCornerRadiusTopRight || 0}px
    0 0
  `};
  max-height: calc(100vh - 8px);

  ${(props) => props.showInfo && css`
    transform: translateY(0); /* Move into view */
  `}
`;

const StyledModalHeader = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  height: 56px;
  min-height: 56px;
  border-bottom: 1px solid ${({theme}) => `${theme.textContentColor}30`};
  border-top-left-radius:  ${({theme}) => `${theme.cardCornerRadiusTopLeft || 0}px`};
  border-top-right-radius: ${({theme}) => `${theme.cardCornerRadiusTopRight || 0}px`};
  padding: 0 12px;

  h2 {
    flex: 1;
    margin: 0;
    font-size: ${(props) => props.theme.typographyFontSizeLarge};
    text-align: center;
  }
`;

const StyledModalContent = styled.section`
  padding: 24px 16px;
  flex: 1;
  overflow: auto;
  
  ul {
    padding-left: 24px;
  }
`;

export {
  StyledModalOverlay,
  StyledModalBody,
  StyledModalHeader,
  StyledModalContent
};
