import styled, {css} from 'styled-components';
import {flexContainer, fontStyle} from '../../helpers/styled';

const StyledTabLinkWrapper = styled.div`
  ${flexContainer};
  grid-gap: 8px;
  margin-top: 16px;
`;

const StyledTabButton = styled.button`
  width: 74px;
  height: 36px;
  border: ${(props) => props.theme.mainButtonBorderWidth} solid ${(props) => props.theme.mainButtonBorderColor};
  border-radius: 4px;
  color: ${({theme}) => `${theme.textTitleColor}80`};
  text-transform: capitalize;
  ${fontStyle('regular')};
  background-color: unset;
  
  ${(props) => props.isActive && css`
    border: 1px solid ${() => props.theme.textButtonBackgroundColor};
    color: ${() => props.theme.textButtonBackgroundColor};
    ${fontStyle('semiBold')};
  `}
`;

export default StyledTabLinkWrapper;
export {
  StyledTabButton
};
