import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

/* Styled Components */
import {
  StyledItemValue,
  StyledItemLabel
} from '../paymentsHistory/templateOne/web/historyWeb.styled';
import {StyledButtonWithoutBg} from '../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../styledComponents/icon/icon.styled';
import StyledSectionDivider, {
  StyledListItem,
  StyledItemsTitle,
  StyledListItemsWrapper,
  StyledItemTitleWrapper
} from '../../styledComponents/list/list.styled';

function PaymentBalances({
  currencyISO, usedBalance, usedBalanceFee, unUsedBalance, unUsedBalanceFee, withdrawalBalance, isMobile
}) {
  const {t} = useTranslation();
  const [expand, setExpand] = useState(isMobile);

  return (
    <StyledSectionDivider>
      <StyledItemTitleWrapper
        isExpanded={expand}
        isMobile={isMobile}
      >
        <StyledItemsTitle isMobile={isMobile}>
          {t(('balances')?.toLowerCase())}
        </StyledItemsTitle>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {isMobile || (
        <StyledButtonWithoutBg
          type="button"
          onClick={() => {
            setExpand((prev) => !prev);
          }}
        >
          <StyledIconSmall icon={expand ? 'arrowUp' : 'arrowDown'} fontSize="11px" />
        </StyledButtonWithoutBg>
        )}
      </StyledItemTitleWrapper>
      {expand && (
      <StyledListItemsWrapper isMobile={isMobile} className="withoutPaddingLeft">
        <StyledListItem>
          <StyledItemLabel>{`${t(('usedBalance')?.toLowerCase())} / ${t(('fee').toLowerCase())}`}</StyledItemLabel>
          <StyledItemValue>{`${usedBalance} ${currencyISO}/${usedBalanceFee}%`}</StyledItemValue>
        </StyledListItem>
        <StyledListItem>
          <StyledItemLabel>{`${t(('unUsedBalance')?.toLowerCase())} / ${t(('fee').toLowerCase())}`}</StyledItemLabel>
          <StyledItemValue>{`${unUsedBalance} ${currencyISO} / ${unUsedBalanceFee}%`}</StyledItemValue>
        </StyledListItem>
        <StyledListItem>
          <StyledItemLabel>{`${t(('withdrawableAmount')?.toLowerCase())}`}</StyledItemLabel>
          <StyledItemValue>{`${withdrawalBalance} ${currencyISO}`}</StyledItemValue>
        </StyledListItem>
      </StyledListItemsWrapper>
      )}
    </StyledSectionDivider>
  );
}

PaymentBalances.propTypes = {
  currencyISO: PropTypes.string,
  usedBalance: PropTypes.number,
  usedBalanceFee: PropTypes.number,
  unUsedBalance: PropTypes.number,
  unUsedBalanceFee: PropTypes.number,
  withdrawalBalance: PropTypes.number,
  isMobile: PropTypes.bool
};
PaymentBalances.defaultProps = {
  currencyISO: '',
  usedBalance: 0,
  usedBalanceFee: 0,
  unUsedBalance: 0,
  unUsedBalanceFee: 0,
  withdrawalBalance: 0,
  isMobile: false
};

export default PaymentBalances;
