import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledTabButtonContent,
  StyledTabThreeButton
} from '../../navigationTabsWeb/navigationTabsWebThree/navigationTabsWebThree.styled';
import {StyledTabMobileThreeWrapper} from './navigationTabsMobileThree.styled';

function NavigationTabsMobileThree({
  handleTabClick,
  activeTab
}) {
  const {t} = useTranslation();

  return (
    <StyledTabMobileThreeWrapper>
      <StyledTabThreeButton
        type="button"
        name={OPERATION_TYPE_NUMBER.DEPOSIT}
        onClick={handleTabClick}
        activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
        isMobile
      >
        <StyledTabButtonContent activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}>
          <TabDeposit />
          {t(('deposit')?.toLowerCase())}
        </StyledTabButtonContent>
      </StyledTabThreeButton>
      <StyledTabThreeButton
        type="button"
        name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
        onClick={handleTabClick}
        activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
        isMobile
      >
        <StyledTabButtonContent activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}>
          <TabWithdrawal />
          {t(('withdrawal')?.toLowerCase())}
        </StyledTabButtonContent>
      </StyledTabThreeButton>
    </StyledTabMobileThreeWrapper>
  );
}

NavigationTabsMobileThree.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default NavigationTabsMobileThree;
