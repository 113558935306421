import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect, useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import EnhancedFormik from '../enhancedFormik';
import {DEFAULT_PAYMENT_ALERTS, OPERATION_TYPE_BY_NUMBER} from '../../../../constants/common';
import Toggle from '../../../toggle/toggle';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {CashierDetailsContext} from '../../../../contexts/cashierDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';
import {AccountVerificationHistoryContext} from '../../../../contexts/accountVerificationHistoryContext';
import Slider from '../../../slider/slider';
import GenerateCode from '../../../generateCode/generateCode';
import PaymentLimits from '../../../leftSideComponents/paymentLimits';
import PaymentBalances from '../../../leftSideComponents/paymentBalances';
import RenderPaymentForm from '../renderPaymentForm';
import HistoryNavigateButton from '../../../historyNavigateButton/historyNavigateButton';
import PaymentInfoWeb from './paymentInfoWeb';

/* Styled Components */
import {
  StyledCardHeaderTitle,
  StyledCardHeaderWrapper,
  StyledFlexContainerForPsForm,
  StyledLinkItem,
  StyledMainFormAndInfoWrapper,
  StyledPaymentInfoParagraph,
  StyledPaymentInfoRightSide,
  StyledPaymentInfoTitle
} from './formWeb.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledFormWrapper} from '../../../../styledComponents/input/input.styled';
import {StyledButtonWithoutBg} from '../../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import StyledSwitcherContainer, {StyledSwitchLabel} from '../../../toggle/toggled.styled';
import {StyledGenerateCodeWrapper} from '../../../paymentsHistory/templateOne/web/historyWeb.styled';

function FormWeb({
  paymentBalances,
  paymentLimits,
  paymentGeneratedCodeInfo,
  setPaymentGeneratedCodeInfo,
  paymentDescription,
  applePaySession,
  baseUrl,
  alertContext,
  isCurrencySymbol,
  translations,
  payPalTransactionData,
  googlePayTransactionData,
  isDefaultAvailable,
  onPaymentDefaultStatusUpdateHandler,
  deviceType,
  paymentSources
}) {
  const {
    operationType, currency, userId, siteId
  } = useContext(CashierDetailsContext);
  const {isDefault: initialIsDefault, paymentSystemId} = useContext(SelectedPaymentDetailsContext);
  const {getVerificationHistory} = useContext(PaymentFormCallbacksContext);
  const {t} = useTranslation();
  const [showGeneratedCodeInfo, setShowGeneratedCodeInfo] = useState(false);
  const [dataName, setDataName] = useState('');
  const [isDefault, setIsDefault] = useState(initialIsDefault);
  const [verificationHistory, setVerificationHistory] = useState([]);

  /**
   * Toggle mobile info layer visibility
   * @param {boolean} show - Whether to show or hide the info layer
   * @param {boolean} showCodeInfo - Whether to show or hide additional payment control info
   */
  const toggleInfoLayer = (show, showCodeInfo = false) => {
    setShowGeneratedCodeInfo(showCodeInfo);
  };

  /**
   * Handle hide additional payment control info
   */
  const handleBackToDetails = () => {
    setPaymentGeneratedCodeInfo([]);
    toggleInfoLayer(false);
  };

  /**
   * Effect for handling initialIsDefault change in case of payment details change
   */
  useEffect(() => {
    setIsDefault(initialIsDefault);
  }, [initialIsDefault, paymentSystemId]);

  /**
   * Effect to handle changes in paymentGeneratedCodeInfo fields length
   */
  useEffect(() => {
    const hasGeneratedInfo = paymentGeneratedCodeInfo?.length > 0;
    setShowGeneratedCodeInfo(hasGeneratedInfo);
  }, [paymentGeneratedCodeInfo]);

  const defaultToggleHandler = useCallback(() => {
    onPaymentDefaultStatusUpdateHandler({
      isDefault: !isDefault,
      callback: (res) => {
        if (res.data) {
          alertContext?.success(t((DEFAULT_PAYMENT_ALERTS[operationType][isDefault ? 'unSet' : 'set']).toLowerCase()));
          setIsDefault((prev) => !prev);
        } else {
          alertContext?.error(t((DEFAULT_PAYMENT_ALERTS[operationType].error).toLowerCase()));
        }
      }
    });
  }, [isDefault, alertContext]);

  const verificationHistoryContextMemoized = useMemo(() => ({
    getVerificationHistoryHandler: () => {
      getVerificationHistory({
        siteId,
        userId,
        paymentSystemId,
        setVerificationHistory
      });
    },
    clearVerificationHistoryHandler: () => {
      setVerificationHistory([]);
    },
    verificationHistory
  }), [verificationHistory]);

  return (
    <StyledMainWrapper>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <AccountVerificationHistoryContext.Provider value={verificationHistoryContextMemoized}>
        <Slider />
        <StyledFlexContainerForPsForm>
          <StyledFormWrapper>
            <PaymentInfoWeb
              currency={currency}
              isCurrencySymbol={isCurrencySymbol}
            />
            <StyledMainFormAndInfoWrapper>
              {paymentSources?.length ? (
                <StyledGenerateCodeWrapper>
                  <GenerateCode
                    setDataName={setDataName}
                    codeDetailsData={paymentSources}
                    translations={translations}
                    alertContext={alertContext}
                    currency={currency}
                    isCurrencySymbol={isCurrencySymbol}
                  />
                </StyledGenerateCodeWrapper>
              ) : (
                <>
                  <EnhancedFormik
                    baseUrl={baseUrl}
                    applePaySession={applePaySession}
                    alertContext={alertContext}
                    payPalTransactionData={payPalTransactionData}
                    googlePayTransactionData={googlePayTransactionData}
                    formComponent={RenderPaymentForm}
                    deviceType={deviceType}
                    key={paymentSystemId}
                    currency={currency}
                    isCurrencySymbol={isCurrencySymbol}
                  />
                  <HistoryNavigateButton />
                </>
              )}
            </StyledMainFormAndInfoWrapper>
          </StyledFormWrapper>
          <StyledPaymentInfoRightSide>
            {
              showGeneratedCodeInfo ? (
                <>
                  <StyledCardHeaderWrapper>
                    <StyledButtonWithoutBg
                      type="button"
                      onClick={handleBackToDetails}
                    >
                      <StyledIconSmall icon="arrowLeft" fontSize="11px" />
                    </StyledButtonWithoutBg>
                    <StyledCardHeaderTitle>
                      {t((dataName)?.toLowerCase())}
                    </StyledCardHeaderTitle>
                  </StyledCardHeaderWrapper>
                  <GenerateCode
                    setDataName={setDataName}
                    codeDetailsData={paymentGeneratedCodeInfo}
                    translations={translations}
                    alertContext={alertContext}
                    currency={currency}
                    isCurrencySymbol={isCurrencySymbol}
                  />
                </>
              ) : (
                <>
                  {paymentLimits && <PaymentLimits currency={currency} playerCurrentLimit={paymentLimits?.playerCurrentLimit} playerLimit={paymentLimits?.playerLimit} />}
                  {paymentBalances && <PaymentBalances {...paymentBalances} />}
                  {isDefaultAvailable && (
                    <StyledSwitcherContainer>
                      <Toggle
                        handleToggle={defaultToggleHandler}
                        isOn={isDefault}
                      />
                      <StyledSwitchLabel>{t(('setAsDefault')?.toLowerCase())}</StyledSwitchLabel>
                    </StyledSwitcherContainer>
                  )}
                  {paymentDescription && (
                    <>
                      {paymentDescription?.desctiption && (
                        <section>
                          <StyledPaymentInfoTitle>{t(('details')?.toLowerCase())}</StyledPaymentInfoTitle>
                          <StyledPaymentInfoParagraph dangerouslySetInnerHTML={{__html: paymentDescription?.desctiption}} />
                        </section>
                      )}
                      {paymentDescription?.howTo && (
                        <section>
                          <StyledPaymentInfoTitle>{operationType === OPERATION_TYPE_BY_NUMBER.DEPOSIT ? t(('howToDeposit').toLowerCase()) : t(('howToWithdrawal').toLowerCase())}</StyledPaymentInfoTitle>
                          <StyledPaymentInfoParagraph dangerouslySetInnerHTML={{__html: paymentDescription?.howTo}} />
                        </section>
                      )}
                      {paymentDescription?.paymentInfoUrl && (
                        <section>
                          <StyledLinkItem
                            href={paymentDescription?.paymentInfoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {paymentDescription?.paymentInfoUrl}
                          </StyledLinkItem>
                        </section>
                      )}
                    </>
                  )}
                </>
              )
            }
          </StyledPaymentInfoRightSide>
        </StyledFlexContainerForPsForm>
      </AccountVerificationHistoryContext.Provider>
    </StyledMainWrapper>
  );
}

FormWeb.defaultProps = {
  paymentGeneratedCodeInfo: null,
  setPaymentGeneratedCodeInfo: null,
  paymentDescription: null,
  paymentSystemId: null,
  payPalTransactionData: null,
  googlePayTransactionData: null,
  paymentBalances: null,
  paymentLimits: null,
  isDefaultAvailable: false,
  onPaymentDefaultStatusUpdateHandler: null,
  paymentSources: null
};

FormWeb.propTypes = {
  deviceType: PropTypes.number.isRequired,
  paymentGeneratedCodeInfo: PropTypes.array,
  setPaymentGeneratedCodeInfo: PropTypes.func,
  paymentDescription: PropTypes.array,
  paymentSystemId: PropTypes.number,
  applePaySession: PropTypes.bool.isRequired,
  baseUrl: PropTypes.string.isRequired,
  translations: PropTypes.object.isRequired,
  alertContext: PropTypes.object.isRequired,
  isCurrencySymbol: PropTypes.bool.isRequired,
  payPalTransactionData: PropTypes.object,
  googlePayTransactionData: PropTypes.object,
  paymentBalances: PropTypes.object,
  paymentLimits: PropTypes.object,
  isDefaultAvailable: PropTypes.bool,
  onPaymentDefaultStatusUpdateHandler: PropTypes.func,
  paymentSources: PropTypes.array
};
export default FormWeb;
