import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import PaymentItemWeb from './paymentItemWeb';
import Search from '../../../search/search';
import NavigationTabsWeb from '../../../navigationTabs/navigationTabsWeb/navigationTabsWeb';
import {groupNameMapping} from '../../../../constants/common';

/* Styled Components */
import {
  StyledPaymentItemSection,
  StyledGroupName,
  StyledGroupNameWrapper,
  StyledMainWrapper,
  StyledPsWrapper
} from './paymentItemWeb.styled';
import {StyledDashedHr} from '../../../../styledComponents/button/button.styled';

function ListWeb({
  paymentData,
  handlePaymentSystemDetailsPage,
  currency,
  isCurrencySymbol,
  enableSearch,
  isFavoriteGroup,
  onFavoriteClickHandler,
  customizationTheme,
  deviceType,
  hasTabs,
  activeTab,
  setActiveTab,
  translations
}) {
  const [tabVersion, setTabVersion] = useState(customizationTheme?.navigationTabVersion?.value || 'versionOne');

  useEffect(() => {
    setTabVersion(customizationTheme?.navigationTabVersion?.value);
  }, [customizationTheme?.navigationTabVersion?.value]);

  const handleTabClick = (event) => {
    const {name} = event.currentTarget;
    setActiveTab(name);
  };

  return (
    <StyledMainWrapper>
      {hasTabs && paymentData?.length ? (
        <NavigationTabsWeb
          tabVersion={tabVersion}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          deviceType={deviceType}
        />
      ) : null}
      {enableSearch ? <Search /> : null}
      {paymentData?.map((pasItem, indexOne) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledPaymentItemSection key={`${pasItem.groupId}:${indexOne}`}>
          {/* eslint-disable-next-line no-nested-ternary */}
          {pasItem.groupId !== 0 ? (
            <StyledGroupNameWrapper>
              <StyledGroupName>
                {translations[groupNameMapping[pasItem?.groupId]?.toLowerCase()]}
                <span>{pasItem?.cashierPaymentSystem?.length}</span>
              </StyledGroupName>
            </StyledGroupNameWrapper>
          ) : paymentData.length > 1 ? <StyledDashedHr hasMarginBottom /> : null}
          <StyledPsWrapper>
            {/* eslint-disable-next-line no-shadow */}
            {pasItem?.cashierPaymentSystem?.map((paymentInfo, indexTwo) => (
              <PaymentItemWeb
                isCurrencySymbol={isCurrencySymbol}
                /* eslint-disable-next-line react/no-array-index-key */
                key={`${paymentInfo?.paymentSystemId}:${indexTwo}`}
                paymentInfo={paymentInfo}
                handlePaymentSystemDetailsPage={handlePaymentSystemDetailsPage}
                isFavoriteGroup={isFavoriteGroup}
                onFavoriteClickHandler={onFavoriteClickHandler}
                currency={currency}
              />
            ))}
          </StyledPsWrapper>
        </StyledPaymentItemSection>
      ))}
    </StyledMainWrapper>
  );
}

ListWeb.defaultProps = {
  paymentData: null,
  handlePaymentSystemDetailsPage: null,
  currency: null,
  enableSearch: false,
  onFavoriteClickHandler: null,
  isFavoriteGroup: false,
  deviceType: null
};

ListWeb.propTypes = {
  paymentData: PropTypes.array,
  handlePaymentSystemDetailsPage: PropTypes.func,
  onFavoriteClickHandler: PropTypes.func,
  currency: PropTypes.string,
  enableSearch: PropTypes.bool,
  isFavoriteGroup: PropTypes.bool,
  isCurrencySymbol: PropTypes.bool.isRequired,
  deviceType: PropTypes.number,
  customizationTheme: PropTypes.object.isRequired,
  hasTabs: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  translations: PropTypes.object.isRequired
};

export default ListWeb;
