import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledTabButtonContent,
  StyledTabThreeButton,
  StyledTabWebThreeWrapper
} from './navigationTabsWebThree.styled';
import {
  StyledTabWebButtonsWrapper
} from '../navigationTabsWebTwo/navigationTabsWebTwo.styled';

function NavigationTabsWebThree({
  handleTabClick,
  activeTab
}) {
  const {t} = useTranslation();

  return (
    <StyledTabWebThreeWrapper spaceBetween>
      <StyledTabWebButtonsWrapper>
        <StyledTabThreeButton
          type="button"
          name={OPERATION_TYPE_NUMBER.DEPOSIT}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
        >
          <StyledTabButtonContent activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}>
            <TabDeposit />
            {t(('deposit')?.toLowerCase())}
          </StyledTabButtonContent>
        </StyledTabThreeButton>
        <StyledTabThreeButton
          type="button"
          name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
        >
          <StyledTabButtonContent activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}>
            <TabWithdrawal />
            {t(('withdrawal')?.toLowerCase())}
          </StyledTabButtonContent>
        </StyledTabThreeButton>
      </StyledTabWebButtonsWrapper>
    </StyledTabWebThreeWrapper>
  );
}

NavigationTabsWebThree.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default NavigationTabsWebThree;
