import instance from './apiService';
import Config from '../configs/mainConfig';

/**
 * Retrieves merchant user multistep controls for a specific site, payment system, operation type, and user.
 *
 * @param {function} setPaymentControlsData - A function to set the retrieved multistep controls data.
 * @param {number} siteId - The numeric identifier of the site.
 * @param {number} paymentSystemId - The identifier of the payment system.
 * @param {number} operationType - The type of operation for the payment system.
 * @param {number} userId - The user identifier.
 * @param {string} currency - The currency ISO CODE identifier.
 * @param {string} language - The language ISO CODE identifier.
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 *
 * @returns {Promise} - A promise that resolves with the retrieved multistep controls data
 *                    or rejects with an error message.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setPaymentControlsData = (controlsData) => {
 *   // Update UI with retrieved multistep controls data
 * };
 *
 * const siteId = 123;
 * const paymentSystemId = 456;
 * const operationType = 1;
 * const userId = 456;
 * const currencyISO = ron;
 * const languageISO = ro;
 * const userId = 456;
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   }
 * };
 *
 * merchantUserMultistepControls (
 *        setPaymentControlsData,
 *        siteId,
 *        paymentSystemId,
 *        operationType,
 *        userId,
 *        currencyISO,
 *        languageISO,
 *        setIsLoading,
 *        alertContext
 *    )
 *   .then((controlsData) => {
 *     // Handle retrieved multistep controls data
 *   })
 *   .catch((error) => {
 *     console.error('Failed to retrieve multistep controls data:', error.message);
 *   });
 */
export const merchantUserMultistepControls = (
  setPaymentControlsData,
  siteId,
  paymentSystemId,
  operationType,
  userId,
  currency,
  language,
  setIsLoading,
  alertContext
) => {
  setIsLoading(true);
  return instance.get(
    `${Config.apiURLs.merchantUserMultistepControls}/${siteId}/${paymentSystemId}/${operationType}/${userId}/${currency}/${language}`,
    {
      param: {
        siteId,
        paymentSystemId,
        operationType,
        userId,
        currency,
        language
      }
    }
  )
    .then((response) => {
      setPaymentControlsData(response?.data);
      return response.data;
    })
    .catch((error) => {
      alertContext?.error(error?.response?.data);
      throw error;
    })
    .finally(() => setIsLoading(false));
};
