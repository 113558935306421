import styled from 'styled-components';
import {flexContainer, fontStyle, getBorderRadius} from '../../../../helpers/styled';

const StyledTabWebTwoWrapper = styled.div`
  height: 52px;
  ${flexContainer()};
  margin: -24px -24px 8px -24px;
  padding: 0 24px;
  border-bottom: ${({theme}) => (theme.navigationVersionTwoGeneralHasBorder
    ? `${theme.navigationVersionTwoGeneralBorderWidth}px solid ${theme.navigationVersionTwoGeneralBorderColor}`
    : '0')};
  background: ${({theme}) => theme.navigationVersionTwoGeneralBGColor};
`;

const StyledTabWebButtonsWrapper = styled.div`
  ${flexContainer()};
  grid-gap: ${({hasGap}) => (hasGap ? '16px' : '0')};
`;

const StyledTabTwoButton = styled.button`
  height: 36px;
  width: ${({activeTab}) => (activeTab ? 'auto' : '52px')};
  ${flexContainer()};
  grid-gap: 8px;
  padding: ${({activeTab}) => (activeTab ? '0 24px' : '0')};
  border: ${({activeTab, theme}) => (activeTab && theme.navigationVersionTwoSelectedHasBorder
    ? `${theme.navigationVersionTwoSelectedBorderWidth}px solid ${theme.navigationVersionTwoSelectedBorderColor}`
    : '0')};
  border-radius: ${(props) => getBorderRadius(props, 'navigationVersionTwoSelected')}; // Handles border-radius for all corners
  ${fontStyle('semiBold')};
  color: ${({theme}) => theme.navigationVersionTwoSelectedTextColor};
  background: ${({activeTab, theme}) => (activeTab ? theme.navigationVersionTwoSelectedBGColor : 'unset')};

  svg path {
    fill: ${({activeTab, theme}) => (activeTab
    ? theme.navigationVersionTwoSelectedIconColor : theme.navigationVersionTwoGeneralIconColor)};
  }
`;

export {
  StyledTabWebTwoWrapper,
  StyledTabWebButtonsWrapper,
  StyledTabTwoButton
};
