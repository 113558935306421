import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';
import PaymentCashierRoute from '../components/router/router';
import {AlertProvider} from '../context/alertContext';
import {loginOnOldPlat} from '../services/authentication';
import {isJWT} from '../helpers/helperFunction';

export default function PaymentCashier() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [applePaySession, setApplePaySession] = useState(null);

  localStorage.setItem('token', message?.token);
  localStorage.setItem('userId', message?.userId);
  localStorage.setItem('siteId', message?.siteId);

  const onMessage = (e) => {
    if (e?.data?.userData) {
      if (isJWT(e?.data?.userData?.token)) {
        console.log('JWT: ', jwtDecode(e?.data?.userData?.token));
      }
      console.log('post message userData', e?.data?.userData);
      setMessage(e.data?.userData);
    }
    if (e?.data?.applePaySession) {
      setApplePaySession(e.data.applePaySession.canMakePayments);
    }
  };

  useEffect(() => {
    window.addEventListener('message', onMessage);
    window.parent.postMessage({messageType: 'ready'}, '*');
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  useEffect(() => {
    if (message?.siteId && message?.userId) {
      loginOnOldPlat({
        siteId: message?.siteId,
        userId: message?.userId,
        lang: message?.lang,
        token: message?.token
      }, setIsLoading, setIsAuth);
    }
  }, [message?.siteId, message?.userId, message?.lang, message?.token]);

  return (
    <Router>
      {isAuth ? (
        <AlertProvider>
          <PaymentCashierRoute
            siteId={message?.siteId}
            userId={message?.userId}
            bonusId={message?.bonusId}
            gaMeasurementId={message?.gaMeasurementId}
            currency={message?.currency}
            country={message?.country}
            token={message?.token}
            language={message?.lang}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            applePaySession={applePaySession}
            cashierMode={message?.cashierMode ? message?.cashierMode : message?.operationType}
          />
        </AlertProvider>
      ) : null}
    </Router>
  );
}
