import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {formatDate, getCurrencySymbolOrCode} from '../../../../helpers/common';

/* Styled Components */
import StyledHistoryList, {
  StyledHistoryListItem,
  StyledHistoryListItemValue,
  StyledViewWrapper
} from '../web/historyWeb.styled';
import {
  StyledCycleMobile,
  StyledHistoryListItemInfoWrapperMobile,
  StyledHistoryListItemTitleMobile
} from './historyMobile.styled';

function HistoryTabContentMobile({
  historyData,
  getSelectedHistoryDetails,
  isCurrencySymbol,
  setCurrency
}) {
  const {t} = useTranslation();
  const [selectedItem, setSelectedItem] = useState(null);
  const handleDetailsInfo = (detailData) => {
    setSelectedItem(detailData);
    setCurrency(detailData?.currencyISO);
    getSelectedHistoryDetails(detailData?.data);
  };

  return (
    <StyledHistoryList>
      {historyData?.map((historyItem) => (
        <StyledHistoryListItem
          key={historyItem?.transactionId}
          selectedItem={selectedItem?.transactionId === historyItem?.transactionId}
          onClick={() => handleDetailsInfo(historyItem)}
        >
          <StyledHistoryListItemInfoWrapperMobile>
            <StyledHistoryListItemTitleMobile>
              {t(('transactionId')?.toLowerCase())}
              :
              <StyledHistoryListItemValue>
                {historyItem?.transactionId}
              </StyledHistoryListItemValue>
            </StyledHistoryListItemTitleMobile>
            <StyledCycleMobile />
            <StyledHistoryListItemTitleMobile>
              {t(('date')?.toLowerCase())}
              :
              <StyledHistoryListItemValue>
                {formatDate(historyItem?.date)}
              </StyledHistoryListItemValue>
            </StyledHistoryListItemTitleMobile>
            <StyledCycleMobile />
            <StyledHistoryListItemTitleMobile>
              {t(('Amount')?.toLowerCase())}
              :
              {' '}
              <StyledHistoryListItemValue>
                {historyItem?.amount}
                {' '}
                {getCurrencySymbolOrCode(historyItem?.currencyISO, isCurrencySymbol)}
              </StyledHistoryListItemValue>
            </StyledHistoryListItemTitleMobile>
          </StyledHistoryListItemInfoWrapperMobile>
          <StyledViewWrapper>
            <img src="https://cdn-payments.apidigi.com/Cashire/images/eye.svg" alt="Eye" />
          </StyledViewWrapper>
        </StyledHistoryListItem>
      ))}
    </StyledHistoryList>
  );
}

HistoryTabContentMobile.defaultProps = {
  historyData: null,
  getSelectedHistoryDetails: null,
  isCurrencySymbol: null
};

HistoryTabContentMobile.propTypes = {
  historyData: PropTypes.array,
  getSelectedHistoryDetails: PropTypes.func,
  isCurrencySymbol: PropTypes.bool,
  setCurrency: PropTypes.func.isRequired
};

export default HistoryTabContentMobile;
