import instance from './apiService';
import Config from '../configs/mainConfig';

export const getTemplateTranslation = (setTemplateTranslation, siteId, language, setIsLoading, alertContext) => {
  setIsLoading(true);
  instance.get(
    `${Config.apiURLs.getTemplateTranslation}/${siteId}/${language}`,
    {
      param: {
        siteId,
        language
      }
    }
  )
    .then((response) => {
      const translationPairs = {};
      response.data.forEach((item) => {
        const lowerCaseKey = item.translationKey.toLowerCase();
        translationPairs[lowerCaseKey] = item.translation;
      });
      setTemplateTranslation(translationPairs);
    })
    .catch((error) => alertContext.error(error.response.data));
};
