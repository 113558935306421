import {BASE_URL, DEVICE_TYPE, TEMPLATE_TYPE} from '../constants/common';
import {PAYMENT_CONTROL_TYPE} from '../constants/formControlType';
import ListWeb from '../components/paymentsList/templateOne/web/listWeb';
import FormWeb from '../components/paymentsForm/templateOne/web/formWeb';
import HistoryWeb from '../components/paymentsHistory/templateOne/web/historyWeb';
import ListMobile from '../components/paymentsList/templateOne/mobile/listMobile';
import FormMobile from '../components/paymentsForm/templateOne/mobile/formMobile';
import HistoryMobile from '../components/paymentsHistory/templateOne/mobile/historyMobile';
import NavigationTabsWebOne from '../components/navigationTabs/navigationTabsWeb/navigationTabsWebOne/navigationTabsWebOne';
import NavigationTabsWebTwo from '../components/navigationTabs/navigationTabsWeb/navigationTabsWebTwo/navigationTabsWebTwo';
import NavigationTabsWebThree from '../components/navigationTabs/navigationTabsWeb/navigationTabsWebThree/navigationTabsWebThree';
import NavigationTabsMobileOne from '../components/navigationTabs/navigationTabsMobile/navigationTabsMobileOne/navigationTabsMobileOne';
import NavigationTabsMobileTwo from '../components/navigationTabs/navigationTabsMobile/navigationTabsMobileTwo/navigationTabsMobileTwo';
import NavigationTabsMobileThree from '../components/navigationTabs/navigationTabsMobile/navigationTabsMobileThree/navigationTabsMobileThree';
import {
  TextBoxField,
  LabelField,
  DynamicLabelField,
  DynamicDropdownField,
  NewDynamicDropdownField,
  SelectField,
  NewSelectField,
  BankAmountListField,
  QrCodeField,
  StatusMessageField,
  CheckboxField,
  ButtonField,
  ButtonWithMultiUrl,
  ApplePayButtonField,
  GooglePayButtonField,
  PayPalButtonField,
  DocumentUploadField,
  BackToFormButton,
  HiddenField,
  SafetyPayGuideAndButtonField
} from '../components/paymentsForm/templateOne/controlsType/controlsType';

const Config = {
  apiURLs: {
    baseURL: BASE_URL,
    transactionCreate: 'TransactionCreate'
  }
};

export default Config;

export const templateComponents = {
  [TEMPLATE_TYPE.TEMPLATE_ONE]: {
    [DEVICE_TYPE.WEB]: {
      ListComponent: ListWeb,
      FormComponent: FormWeb,
      HistoryComponent: HistoryWeb
    },
    [DEVICE_TYPE.MOBILE]: {
      ListComponent: ListMobile,
      FormComponent: FormMobile,
      HistoryComponent: HistoryMobile
    }
  },
  [TEMPLATE_TYPE.TEMPLATE_TWO]: {
    [DEVICE_TYPE.WEB]: {
      ListComponent: '',
      FormComponent: ''
    },
    [DEVICE_TYPE.MOBILE]: {
      ListComponent: '',
      FormComponent: ''
    }
  }
};

export const navigationTabsComponents = {
  [DEVICE_TYPE.WEB]: {
    versionOne: NavigationTabsWebOne,
    versionTwo: NavigationTabsWebTwo,
    versionThree: NavigationTabsWebThree
  },
  [DEVICE_TYPE.MOBILE]: {
    versionOne: NavigationTabsMobileOne,
    versionTwo: NavigationTabsMobileTwo,
    versionThree: NavigationTabsMobileThree
  }
};

// Common components (same for both web and mobile)
const commonControlsMapping = {
  [PAYMENT_CONTROL_TYPE.TEXT_BOX]: TextBoxField,
  [PAYMENT_CONTROL_TYPE.LABEL]: LabelField,
  [PAYMENT_CONTROL_TYPE.DYNAMIC_LABEL]: DynamicLabelField,
  [PAYMENT_CONTROL_TYPE.DYNAMIC_DROPDOWN]: DynamicDropdownField,
  [PAYMENT_CONTROL_TYPE.NEW_DYNAMIC_LIST]: NewDynamicDropdownField,
  [PAYMENT_CONTROL_TYPE.SELECT]: SelectField,
  [PAYMENT_CONTROL_TYPE.NEW_STATIC_LIST]: NewSelectField,
  [PAYMENT_CONTROL_TYPE.BANK_AMOUNT_LIST]: BankAmountListField,
  [PAYMENT_CONTROL_TYPE.QRCODE]: QrCodeField,
  [PAYMENT_CONTROL_TYPE.STATUS_MESSAGE]: StatusMessageField,
  [PAYMENT_CONTROL_TYPE.CHECKBOX]: CheckboxField,
  [PAYMENT_CONTROL_TYPE.BUTTON]: ButtonField,
  [PAYMENT_CONTROL_TYPE.BUTTON_WIDTH_URL]: ButtonField,
  [PAYMENT_CONTROL_TYPE.BUTTON_WITH_MULTI_URL]: ButtonWithMultiUrl,
  [PAYMENT_CONTROL_TYPE.APPLE_PAY_BUTTON]: ApplePayButtonField,
  [PAYMENT_CONTROL_TYPE.PAYPAL_BUTTON]: PayPalButtonField,
  [PAYMENT_CONTROL_TYPE.GOOGLE_PAY_BUTTON]: GooglePayButtonField,
  [PAYMENT_CONTROL_TYPE.SAFETY_PAY_GUIDE_AND_BUTTON]: SafetyPayGuideAndButtonField,
  [PAYMENT_CONTROL_TYPE.UPLOAD_DOCUMENT]: DocumentUploadField,
  [PAYMENT_CONTROL_TYPE.BACK_TO_FORM]: BackToFormButton,
  [PAYMENT_CONTROL_TYPE.HIDDEN]: HiddenField
};

// Web-specific components
export const WEB_CONTROLS_MAPPING = {
  ...commonControlsMapping
};

// Mobile-specific components
export const MOBILE_CONTROLS_MAPPING = {
  ...commonControlsMapping
};
