/**
 * All constant values that used in project
 */
export const PAYMENTS_IDS = {
  APPLE_PAY_ID: 1561,
  PAYPAL_ID: 1799,
  GOOGLE_PAY_ID: 1847,
  SAFETY_PAY_IDS: [2131, 2179, 2172, 2178, 2173, 2175, 2171, 2170, 2177, 2174]
};

export const ALERT_TYPE = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};

export const CONTROL_STATE = {
  SUCCESS: 1,
  FAIL: 2,
  IN_PROCESS: 3
};

/**
 * GETTING DEVICE TYPE
 * */
export const DEVICE_TYPE = {
  WEB: 1,
  MOBILE: 2
};

export const OPERATION_TYPE_BY_NUMBER = {
  DEPOSIT: 1,
  WITHDRAWAL: 2
};

export const CASHIER_MODE = {
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  BOTH: 'both',
  QUICK_FORM: 'quickForm'
};

export const DEFAULT_LANGUAGE = 'default';

export const ALERT_TYPES = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
};

export const DEVICE_SIZE = {
  MOBILE: 800
};

export const BACK_TO_FORM_BUTTON_CONTROL = {
  controlTypeId: 999,
  controlTypeName: 'BackToForm',
  dynamicControlGroupItems: null,
  fieldControls: [],
  id: 300,
  key: 'backtoform',
  name: 'BackToFormStep',
  order: 6,
  stepId: 1
};
