import instance from './apiService';
import Config from '../configs/mainConfig';

const {baseURL, merchantVerificationHistory} = Config.apiURLs;

export const getVerificationHistory = async({
  siteId, userId, paymentSystemId, setVerificationHistory
}) => {
  await instance.get(
    `${baseURL}${merchantVerificationHistory}/${siteId}/${userId}/${paymentSystemId}`,
    {params: {siteId, userId, paymentSystemId}}
  )
    .then((res) => setVerificationHistory(res.data));
};
