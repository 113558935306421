import React from 'react';
import ReactDOM from 'react-dom/client';
// import {FpjsProvider} from '@fingerprintjs/fingerprintjs-pro-react';
import PaymentCashier from './index/PaymentCashier';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<PaymentCashier />);

// <FpjsProvider
//   loadOptions={{
//     apiKey: 'g22AX29DrJOhDJCKfvDD'
//   }}
// >
//   <PaymentCashier />
// </FpjsProvider>
