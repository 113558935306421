import instance from './apiService';
import Config from '../configs/mainConfig';

export const updatePaymentDefaultStatus = async({
  siteId,
  paymentSystemId,
  userId,
  paymentMethodType,
  isDefault,
  callback
}) => {
  await instance.post(
    `${Config.apiURLs.baseURL}${Config.apiURLs.updatePaymentDefaultStatus}`,
    {
      userId: JSON.stringify(userId),
      paymentSystemId,
      siteId,
      paymentMethodType,
      isDefault
    }
  ).then((res) => { callback(res); });
};
