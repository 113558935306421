import React from 'react';
import PropTypes from 'prop-types';
import {navigationTabsComponents} from '../../../configs/mainConfig';

function NavigationTabsWeb({
  handleTabClick,
  activeTab,
  deviceType,
  tabVersion
}) {
  const DynamicComponent = navigationTabsComponents?.[deviceType]?.[tabVersion];

  return (
    <DynamicComponent
      handleTabClick={handleTabClick}
      activeTab={activeTab.toString()}
    />
  );
}

NavigationTabsWeb.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  deviceType: PropTypes.number.isRequired,
  tabVersion: PropTypes.string.isRequired
};

export default NavigationTabsWeb;
