/**
 * Configurable data in project
 *
 * @const {Object}
 */

const Config = {
  apiURLs: {
    baseURL: 'https://pygcashierapi.letspayments.com/api/',
    authenticate: 'authenticate',
    oldPlatAuthenticate: 'oldplatauthenticate',
    merchantUserMultistepControls: 'merchantUserMultistepControls',
    getPaymentSystemByMerchantUser: 'getPaymentSystemByMerchantUser',
    getSitePaymentSystemControls: 'getSitePaymentSystemControls',
    getSiteActiveTemplateDetail: 'getSiteActiveTemplateDetail',
    getSitePaymentSystemDescriptions: 'getSitePaymentSystemDescriptions',
    getTemplateTranslation: 'translation',
    getPaymentSystemTransactionsHistory: 'getPaymentSystemTransactionsHistory',
    getPaymentLimits: 'getMerchantUserRemainingLimits',
    getPaymentBalances: 'getMerchantUserWithdrawableBalance',
    updateFavoritePayments: 'UpdateMerchantUserFavoritePayments',
    updatePaymentDefaultStatus: 'UpdateMerchantUserDefaultPayment',
    merchantVerificationHistory: 'getMerchantUserAccountNumber',
    merchantUserVerificationControls: 'getUserAccountsVerficationControls'
  }
};

export default Config;
