import instance from './apiService';
import Config from '../configs/mainConfig';

export const addRemoveFavorite = async({
  siteId,
  paymentSystemId,
  userId,
  paymentMethodType
}) => {
  await instance.post(
    `${Config.apiURLs.baseURL}${Config.apiURLs.updateFavoritePayments}`,
    {
      userId: JSON.stringify(userId),
      paymentSystemId,
      siteId,
      paymentMethodType
    }
  );
};
