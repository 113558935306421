import styled, {css} from 'styled-components';
import {flexContainer, fontStyle, getBorderRadius} from '../../../../helpers/styled';

const StyledTabMobileTwoWrapper = styled.div`
  position: ${({theme}) => (theme.adminWrapper ? 'sticky' : 'fixed')};
  bottom: 0;
  left: 0;
  right: 0;
  height: 52px;
  ${flexContainer()};
  border-top: ${(props) => (props.theme.navigationVersionTwoGeneralHasBorder
    ? `${props.theme.navigationVersionTwoGeneralBorderWidth}px solid ${props.theme.navigationVersionTwoGeneralBorderColor}`
    : '0')};
  background: ${({theme}) => theme.navigationVersionTwoGeneralBGColor};
  z-index: ${({theme}) => theme.zIndex9999};
`;

const StyledMobileTwoButton = styled.button`
  height: 36px;
  ${flexContainer()};
  gap: 8px;
  border: 0;
  border-radius: ${(props) => getBorderRadius(props, 'navigationVersionTwoSelected')}; // Handles border-radius for all corners
  ${fontStyle('semiBold')};
  transition: flex-grow .5s;

  ${({activeTab}) => css`
    ${activeTab && css`
      width: auto;
      flex-grow: 1;
      margin: 0 16px;
      color: ${({theme}) => theme.navigationVersionTwoSelectedTextColor};
      background: ${({theme}) => theme.navigationVersionTwoSelectedBGColor};
      border: ${({theme}) => (theme.navigationVersionTwoSelectedHasBorder
    ? `${theme.navigationVersionTwoSelectedBorderWidth}px solid ${theme.navigationVersionTwoSelectedBorderColor}`
    : '0')};
      
      svg path {
        fill: ${({theme}) => theme.navigationVersionTwoSelectedIconColor};
      }
    `}

    ${!activeTab && css`
      width: 52px;
      flex-grow: 0;
      margin: 0;
      background: unset;

      svg path {
        fill: ${({theme}) => theme.navigationVersionTwoGeneralIconColor};
      }

      span {
        display: none;
      }
    `}
  `}
`;

export {
  StyledTabMobileTwoWrapper,
  StyledMobileTwoButton
};
