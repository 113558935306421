import styled from 'styled-components';
import {flexContainer, getBorderRadius} from '../../helpers/styled';

const sliderItemPadding = 4; // The padding value in pixels

const StyledSliderItem = styled.div`
  width: 116px;
  height: 64px;
  ${flexContainer};
  border: ${(props) => (props.selectedItem
    ? `2px solid ${props.theme.mainButtonBackgroundColor}` : 0)};
  border-radius: ${(props) => {
    if (!props.selectedItem) return '0';
    const {
      sliderCornerRadiusTopLeft = '0',
      sliderCornerRadiusTopRight = '0',
      sliderCornerRadiusBottomRight = '0',
      sliderCornerRadiusBottomLeft = '0'
    } = props.theme;

    const topLeft = parseFloat(sliderCornerRadiusTopLeft) || 0;
    const topRight = parseFloat(sliderCornerRadiusTopRight) || 0;
    const bottomRight = parseFloat(sliderCornerRadiusBottomRight) || 0;
    const bottomLeft = parseFloat(sliderCornerRadiusBottomLeft) || 0;

    return `${topLeft + sliderItemPadding}px ${topRight + sliderItemPadding}px ${bottomRight + sliderItemPadding}px ${bottomLeft + sliderItemPadding}px`;
  }};
  padding: ${sliderItemPadding}px; // Applying the same padding value here
  
  img {
    width: 100%;
    height: 100%;
  }
`;

const StyledLogoWrapper = styled.div`
  position: relative;
  width: 104px;
  height: 52px;
  text-align: center;
  background-color: ${(props) => props.theme.generalColorNeutralMinimum};
  border-radius: ${(props) => getBorderRadius(props, 'slider')}; // Handles border-radius for all corners
  overflow: hidden;
`;

export {StyledSliderItem, StyledLogoWrapper};
