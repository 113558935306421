import styled from 'styled-components';
import {flexContainer, fontStyle} from '../../helpers/styled';

const StyledLabelInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 24px;
    //margin-bottom: 8px;
`;

const StyledLabelInfo = styled.p`
    text-align: center;
    ${fontStyle('regular')};
    line-height: 24px;
    margin: ${(props) => (props.withoutMargin ? 'unset' : '0 auto')};
    color: ${(props) => props.theme.cardTextValueColor};

    a {
        color: ${(props) => props.theme.textButtonBackgroundColor};
    }
`;

const StyledLabelInfoForDynamicLabel = styled.h3`
  ${flexContainer};
  justify-content: space-between;
  ${fontStyle('semiBold')};
  color: ${(props) => props.theme.cardTextValueColor};
`;

export default StyledLabelInfoWrapper;
export {
  StyledLabelInfo,
  StyledLabelInfoForDynamicLabel
};
