import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useTheme} from 'styled-components';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledHideShowTab
} from './navigationTabsMobileOne.styled';
import {
  StyledTabOneButtonsWrapper,
  StyledTabOneButton
} from '../../navigationTabsWeb/navigationTabsWebOne/navigationTabsWebOne.styled';

function NavigationTabsMobileOne({
  handleTabClick,
  activeTab,
  isVisible
}) {
  const {t} = useTranslation();
  const theme = useTheme();

  return (
    <StyledHideShowTab
      isVisible={isVisible}
    >
      <StyledTabOneButtonsWrapper>
        <StyledTabOneButton
          type="button"
          name={OPERATION_TYPE_NUMBER.DEPOSIT}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
          isMobile
        >
          {theme.navigationVersionOneGeneralHasIcon ? (
            <TabDeposit />
          ) : null}
          {t(('deposit')?.toLowerCase())}
        </StyledTabOneButton>
        <StyledTabOneButton
          type="button"
          name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
          isMobile
        >
          {theme.navigationVersionOneGeneralHasIcon ? (
            <TabWithdrawal />
          ) : null}
          {t(('withdrawal')?.toLowerCase())}
        </StyledTabOneButton>
      </StyledTabOneButtonsWrapper>
    </StyledHideShowTab>
  );
}

NavigationTabsMobileOne.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  isVisible: PropTypes.bool.isRequired
};

export default NavigationTabsMobileOne;
