import {css} from 'styled-components';
import {ALERT_TYPE} from '../constants/common';
import {theme} from '../theme/theme';

export const MOBILE_SIZE = '800px';

/**
 * Iterates over provided CSS properties object
 * and returns styled-components css.
 *
 * @param {String} cssPropName - example: 'padding'
 * @param {Object} propObj - example: { top: '10px', right: '10px' }
 *
 * @returns {Array}
 */
export function iterateCssProps(cssPropName, propObj = {}) {
  if (!cssPropName) {
    return [];
  }

  const propEntries = Object.entries(propObj);
  const propsFormatted = [];

  if (propEntries.length) {
    propEntries.forEach(([key, value]) => {
      const cssVal = `${cssPropName}-${key}: ${value};`;
      propsFormatted.push(cssVal);
    });
  }

  return css`${propsFormatted}`;
}

/**
 * Creates text truncation CSS props.
 *
 * If text is not fitting in block, three dots will be added in the end.
 */
export function truncate() {
  return css`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `;
}

/**
 * Define flex style for elements
 *
 * General style for all flex elements
 */
export function flexContainer() {
  return css`
    display: flex;
    justify-content: ${(props) => (props.spaceBetween ? 'space-between' : 'center')};
    align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
    flex-direction: ${(props) => props.flexDirection};
`;
}

/**
 * Define disable style for elements
 *
 * If the button is disabled, these styles are added
 */

export function disable() {
  return css`
    opacity: ${(props) => (props.disabled ? '0.6' : '1')};
    pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
  `;
}

/**
 * Define font style for elements
 *
 * @param {String} type
 *
 */
export function fontStyle(type) {
  switch (type) {
    case 'regular':
      return css`
      font: ${(props) => props.theme.typographyFontWeightRegular} 
            ${(props) => props.theme.typographyFontSizeMiddle}/normal 
            ${(props) => props.theme.typographyFontFamilyBase};
    `;
    case 'medium':
      return css`
        font: ${(props) => props.theme.typographyFontWeightRegular}
        ${(props) => props.theme.typographyFontSizeMiddle}/normal 
        ${(props) => props.theme.typographyFontFamilyBase};
`;
    default:
      return css`
      font: ${(props) => props.theme.typographyFontWeightRegular} 
            ${(props) => props.theme.typographyFontSizeSmall}/normal 
            ${(props) => props.theme.typographyFontFamilyBase};
`;
  }
}

export function generateAlertColor(state) {
  switch (state) {
    case ALERT_TYPE.SUCCESS:
      return `${theme.successColor}`;
    case ALERT_TYPE.WARNING:
      return `${theme.warningColor}`;
    case ALERT_TYPE.ERROR:
      return `${theme.errorColor}`;
    case ALERT_TYPE.INFO:
      return `${theme.infoColor}`;
    default:
      return `${theme.iconColor}`;
  }
}
export function generateAlertBackgroundColor(state) {
  switch (state) {
    case ALERT_TYPE.SUCCESS:
      return `${theme.successBackgroundColor}`;
    case ALERT_TYPE.WARNING:
      return `${theme.warningBackgroundColor}`;
    case ALERT_TYPE.ERROR:
      return `${theme.errorBackgroundColor}`;
    case ALERT_TYPE.INFO:
      return `${theme.infoBackgroundColor}`;
    default:
      return 'rgba(243, 244, 245, 0.7)';
  }
}
