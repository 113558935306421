import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {getCurrencySymbolOrCode, renderProcessingTime} from '../../../../helpers/common';
import {PaymentControlsContext} from '../../../../contexts/paymentControlsContext';
import {SelectedPaymentDetailsContext} from '../../../../contexts/selectedPaymentDetailsContext';
import {PaymentFormCallbacksContext} from '../../../../contexts/paymentFormCallbacksContext';

/* Styled Components */
import {
  StyledPaymentItemLabel,
  StyledPaymentItemValue
} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import {StyledPaymentDetailWrapper, StyledPaymentInfoList, StyledPaymentName} from './formWeb.styled';
import {StyledButtonWithoutBg, StyledFlexContainer} from '../../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';

function PaymentInfoWeb({
  currency,
  isCurrencySymbol
}) {
  const {t} = useTranslation();
  const paymentSystemDetail = useContext(SelectedPaymentDetailsContext);
  const paymentControlsData = useContext(PaymentControlsContext);
  const {backToListHandler} = useContext(PaymentFormCallbacksContext);

  return (
    <div>
      <StyledPaymentDetailWrapper>
        <StyledButtonWithoutBg
          isPositionLeft
          type="button"
          onClick={backToListHandler}
        >
          <StyledIconSmall icon="arrowLeft" fontSize="11px" />
        </StyledButtonWithoutBg>
        <StyledFlexContainer fullWidth>
          <StyledPaymentName>
            {paymentControlsData?.paymentSystemName}
          </StyledPaymentName>
        </StyledFlexContainer>
      </StyledPaymentDetailWrapper>
      <StyledPaymentInfoList>
        <li>
          <StyledPaymentItemLabel>
            {t(('min')?.toLowerCase())}
            :
            <StyledPaymentItemValue>
              {paymentSystemDetail?.min}
              {' '}
              {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
            </StyledPaymentItemValue>
          </StyledPaymentItemLabel>
        </li>
        <li>
          <StyledPaymentItemLabel>
            {t(('max')?.toLowerCase())}
            :
            <StyledPaymentItemValue>
              {paymentSystemDetail?.max}
              {' '}
              {getCurrencySymbolOrCode(currency, isCurrencySymbol)}
            </StyledPaymentItemValue>
          </StyledPaymentItemLabel>
        </li>
        {paymentSystemDetail?.fee !== null ? (
          <li>
            <StyledPaymentItemLabel>
              {t(('fee')?.toLowerCase())}
              :
              <StyledPaymentItemValue>
                {paymentSystemDetail?.fee}
                {' '}
                %
              </StyledPaymentItemValue>
            </StyledPaymentItemLabel>
          </li>
        ) : null}
        {paymentControlsData?.processingTimeDetail ? (
          <li>
            <StyledPaymentItemLabel>
              {t(('transactionTime')?.toLowerCase())}
              :
              <StyledPaymentItemValue>
                {renderProcessingTime(paymentControlsData?.processingTimeDetail, t)}
              </StyledPaymentItemValue>
            </StyledPaymentItemLabel>
          </li>
        ) : null}
      </StyledPaymentInfoList>
    </div>
  );
}

PaymentInfoWeb.defaultProps = {
  currency: null,
  isCurrencySymbol: null
};

PaymentInfoWeb.propTypes = {
  currency: PropTypes.string,
  isCurrencySymbol: PropTypes.bool
};

export default PaymentInfoWeb;
