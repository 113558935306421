import styled from 'styled-components';
import {StyledFormWrapper, StyledStatusMessageWrapper} from '../../../../styledComponents/input/input.styled';
import {flexContainer} from '../../../../helpers/styled';
import {StyledPaymentFormWrapper} from '../web/formWeb.styled';

const StyledFormWrapperMobile = styled(StyledFormWrapper)`
  width: 100%;
  max-width: 100%;
`;

const StyledContentToggleItems = styled.div`
  ${flexContainer};
  justify-content: space-between;
  height: 40px;
`;

const StyledPaymentFormWrapperMobile = styled(StyledPaymentFormWrapper)`
  padding: 0;
  grid-gap: ${({autoGeneratedForm}) => (autoGeneratedForm ? '0' : '24px')};
`;

const StyledStatusMessageWrapperMobile = styled(StyledStatusMessageWrapper)`
  padding: 8px 12px;
  
  i, div {
    display: none;
  }
`;

export {
  StyledFormWrapperMobile,
  StyledContentToggleItems,
  StyledPaymentFormWrapperMobile,
  StyledStatusMessageWrapperMobile
};
