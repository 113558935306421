import instance from './apiService';
import Config from '../configs/mainConfig';

export const merchantUserVerificationControls = async(siteId) => {
  const res = await instance.get(
    `${Config.apiURLs.merchantUserVerificationControls}/${siteId}`,
    {params: {siteId}}
  );

  return res;
};
