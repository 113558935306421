import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {PaymentControlsContext} from '../../contexts/paymentControlsContext';
import {PaymentFormCallbacksContext} from '../../contexts/paymentFormCallbacksContext';

/* Styled Components */
import {StyledLinkButton} from '../../styledComponents/button/button.styled';

function HistoryNavigateButton() {
  const paymentControlsData = useContext(PaymentControlsContext);
  const {navigate} = useContext(PaymentFormCallbacksContext);
  const {t} = useTranslation();

  return (paymentControlsData?.hasHistory ? (
    <StyledLinkButton
      centerAligned
      onClick={() => navigate('/paymentsList/:id/history')}
    >
      {t(('barcodeHistory').toLowerCase())}
    </StyledLinkButton>
  ) : null);
}

export default HistoryNavigateButton;
