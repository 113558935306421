import axios from 'axios';
import Config from '../configs/mainConfig';
import {computeSha256HashFromString} from '../helpers/helperFunction';

/**
 * Gets logged in user's token for requests
 *
 */

const instance = axios.create({
  baseURL: Config.apiURLs.baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=UTF-8',
    userId: localStorage.getItem('userId'),
    siteId: localStorage.getItem('siteId'),
    token: localStorage.getItem('token')
  }
});


function getAuthorization(config) {
  /**
   * Authorization logic for authenticate call
   */
  if (config.url === 'oldplatauthenticate') {
    return `Bearer ${localStorage.getItem('token')}`;
  }

  /**
   * Authorization logic for POST requests
   */
  if (config.method === 'post') {
    const bodyHash = computeSha256HashFromString(JSON.stringify(config.data));
    return `${bodyHash}`;
  }

  /**
   * Authorization logic for GET requests
   */
  if (config.method === 'get') {
    const endpointParams = Object.values(config.param || {}).join('');
    const inputHash = computeSha256HashFromString(endpointParams);
    return `${inputHash}`;
  }

  /**
   * Default authorization logic if the request
   * method is not specified or
   * doesn't match 'post' or 'get'
   */
  return `Bearer ${localStorage.getItem('token')}`;
}

// Add an interceptor to modify the request config before each request
instance.interceptors.request.use((config) => {
  config.headers.token = localStorage.getItem('token');
  config.headers.Authorization = getAuthorization(config);
  return config;
});

export default instance;

