import styled from 'styled-components';
import {
  StyledPsWrapper
} from '../web/paymentItemWeb.styled';

const StyledMobileMainWrapper = styled.main`
    min-height: 100%;
    flex: 1;
    padding: 16px;
    position: relative;
    background-color: ${(props) => props.theme.generalBackgroundColor};
`;
const StyledPsMobileWrapper = styled(StyledPsWrapper)`
    flex-direction: column;
    grid-gap: 8px;
`;

export {
  StyledMobileMainWrapper,
  StyledPsMobileWrapper
};
