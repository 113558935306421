import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {SwitchContainer, Switcher} from './toggled.styled';

function Toggle({isOn, handleToggle}) {
  return (
    <SwitchContainer>
      <Switcher isOn={isOn} onClick={handleToggle} />
    </SwitchContainer>
  );
}

Toggle.propTypes = {
  isOn: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired
};

export default memo(Toggle);
