import styled from 'styled-components';
import {flexContainer, fontStyle, getBorderRadius} from '../../../../helpers/styled';

const StyledTabWebOneWrapper = styled.div`
  ${flexContainer()};
  margin-bottom: 8px;
`;

const StyledTabOneButtonsWrapper = styled.div`
  position: relative;
  height: 48px;
  ${flexContainer()};
  padding: 3px 4px;
  border: ${({theme}) => (theme.navigationVersionOneGeneralHasBorder
    ? `${theme.navigationVersionOneGeneralBorderWidth}px solid ${theme.navigationVersionOneGeneralBorderColor}`
    : '0')};
  border-radius: ${(props) => getBorderRadius(props, 'navigationVersionOneGeneral')}; // Handles border-radius for all corners
  background-color: ${({theme}) => theme.navigationVersionOneGeneralBGColor};
`;

const StyledTabOneButton = styled.button`
  height: 100%;
  width: ${({isMobile}) => (isMobile ? 'auto' : '176px')};
  ${flexContainer()};
  flex: ${({isMobile}) => (isMobile ? '1' : 'unset')};
  grid-gap: 8px;
  border: ${({activeTab, theme}) => (activeTab && theme.navigationVersionOneSelectedHasBorder
    ? `${theme.navigationVersionOneSelectedBorderWidth}px solid ${theme.navigationVersionOneSelectedBorderColor}`
    : '0')};
  border-radius: 30px;
  ${fontStyle('semiBold')};
  color: ${({activeTab, theme}) => (activeTab
    ? theme.navigationVersionOneSelectedTextColor : theme.navigationVersionOneGeneralTextColor)};
  background: ${({activeTab, theme}) => (activeTab
    ? theme.navigationVersionOneSelectedBGColor : 'unset')};
  
  svg path {
    fill: ${({activeTab, theme}) => (activeTab
    ? theme.navigationVersionOneSelectedIconColor : theme.navigationVersionOneGeneralIconColor)};
  }
`;

export {
  StyledTabWebOneWrapper,
  StyledTabOneButtonsWrapper,
  StyledTabOneButton
};
