import styled from 'styled-components';
import {StyledTabMobileTwoWrapper} from '../navigationTabsMobileTwo/navigationTabsMobileTwo.styled';

const StyledTabMobileThreeWrapper = styled(StyledTabMobileTwoWrapper)`
  border-top: ${(props) => (props.theme.navigationVersionThreeGeneralHasBorder
    ? `${props.theme.navigationVersionThreeGeneralBorderWidth}px solid ${props.theme.navigationVersionThreeGeneralBorderColor}`
    : '0')};
  background: ${({theme}) => theme.navigationVersionThreeGeneralBGColor};
`;

export {StyledTabMobileThreeWrapper};
