export const currencyList = {
  USD: {
    symbol: '$',
    code: 'USD'
  },
  CAD: {
    symbol: 'CA$',
    code: 'CAD'
  },
  EUR: {
    symbol: '€',
    code: 'EUR'
  },
  AED: {
    symbol: 'AED',
    code: 'AED'
  },
  AFN: {
    symbol: 'Af',
    code: 'AFN'
  },
  ALL: {
    symbol: 'ALL',
    code: 'ALL'
  },
  AMD: {
    symbol: 'AMD',
    code: 'AMD'
  },
  ARS: {
    symbol: 'AR$',
    code: 'ARS'
  },
  AUD: {
    symbol: 'AU$',
    code: 'AUD'
  },
  AZN: {
    symbol: 'man.',
    code: 'AZN'
  },
  BAM: {
    symbol: 'KM',
    code: 'BAM'
  },
  BDT: {
    symbol: 'Tk',
    code: 'BDT'
  },
  BGN: {
    symbol: 'BGN',
    code: 'BGN'
  },
  BHD: {
    symbol: 'BD',
    code: 'BHD'
  },
  BIF: {
    symbol: 'FBu',
    code: 'BIF'
  },
  BND: {
    symbol: 'BN$',
    code: 'BND'
  },
  BOB: {
    symbol: 'Bs',
    code: 'BOB'
  },
  BRL: {
    symbol: 'R$',
    code: 'BRL'
  },
  BWP: {
    symbol: 'BWP',
    code: 'BWP'
  },
  BYN: {
    symbol: 'Br',
    code: 'BYN'
  },
  BZD: {
    symbol: 'BZ$',
    code: 'BZD'
  },
  CDF: {
    symbol: 'CDF',
    code: 'CDF'
  },
  CHF: {
    symbol: 'CHF',
    code: 'CHF'
  },
  CLP: {
    symbol: 'CL$',
    code: 'CLP'
  },
  CNY: {
    symbol: 'CN¥',
    code: 'CNY'
  },
  COP: {
    symbol: 'CO$',
    code: 'COP'
  },
  CRC: {
    symbol: '₡',
    code: 'CRC'
  },
  CVE: {
    symbol: 'CV$',
    code: 'CVE'
  },
  CZK: {
    symbol: 'Kč',
    code: 'CZK'
  },
  DJF: {
    symbol: 'Fdj',
    code: 'DJF'
  },
  DKK: {
    symbol: 'Dkr',
    code: 'DKK'
  },
  DOP: {
    symbol: 'RD$',
    code: 'DOP'
  },
  DZD: {
    symbol: 'DA',
    code: 'DZD'
  },
  EEK: {
    symbol: 'Ekr',
    code: 'EEK'
  },
  EGP: {
    symbol: 'EGP',
    code: 'EGP'
  },
  ERN: {
    symbol: 'Nfk',
    code: 'ERN'
  },
  ETB: {
    symbol: 'Br',
    code: 'ETB'
  },
  GBP: {
    symbol: '£',
    code: 'GBP'
  },
  GEL: {
    symbol: 'GEL',
    code: 'GEL'
  },
  GHS: {
    symbol: 'GH₵',
    code: 'GHS'
  },
  GNF: {
    symbol: 'FG',
    code: 'GNF'
  },
  GTQ: {
    symbol: 'GTQ',
    code: 'GTQ'
  },
  HKD: {
    symbol: 'HK$',
    code: 'HKD'
  },
  HNL: {
    symbol: 'HNL',
    code: 'HNL'
  },
  HRK: {
    symbol: 'kn',
    code: 'HRK'
  },
  HUF: {
    symbol: 'Ft',
    code: 'HUF'
  },
  IDR: {
    symbol: 'Rp',
    code: 'IDR'
  },
  ILS: {
    symbol: '₪',
    code: 'ILS'
  },
  INR: {
    symbol: 'Rs',
    code: 'INR'
  },
  IQD: {
    symbol: 'IQD',
    code: 'IQD'
  },
  IRR: {
    symbol: 'IRR',
    code: 'IRR'
  },
  ISK: {
    symbol: 'Ikr',
    code: 'ISK'
  },
  JMD: {
    symbol: 'J$',
    code: 'JMD'
  },
  JOD: {
    symbol: 'JD',
    code: 'JOD'
  },
  JPY: {
    symbol: '¥',
    code: 'JPY'
  },
  KES: {
    symbol: 'Ksh',
    code: 'KES'
  },
  KHR: {
    symbol: 'KHR',
    code: 'KHR'
  },
  KMF: {
    symbol: 'CF',
    code: 'KMF'
  },
  KRW: {
    symbol: '₩',
    code: 'KRW'
  },
  KWD: {
    symbol: 'KD',
    code: 'KWD'
  },
  KZT: {
    symbol: 'KZT',
    code: 'KZT'
  },
  LBP: {
    symbol: 'L.L.',
    code: 'LBP'
  },
  LKR: {
    symbol: 'SLRs',
    code: 'LKR'
  },
  LTL: {
    symbol: 'Lt',
    code: 'LTL'
  },
  LVL: {
    symbol: 'Ls',
    code: 'LVL'
  },
  LYD: {
    symbol: 'LD',
    code: 'LYD'
  },
  MAD: {
    symbol: 'MAD',
    code: 'MAD'
  },
  MDL: {
    symbol: 'MDL',
    code: 'MDL'
  },
  MGA: {
    symbol: 'MGA',
    code: 'MGA'
  },
  MKD: {
    symbol: 'MKD',
    code: 'MKD'
  },
  MMK: {
    symbol: 'MMK',
    code: 'MMK'
  },
  MOP: {
    symbol: 'MOP$',
    code: 'MOP'
  },
  MUR: {
    symbol: 'MURs',
    code: 'MUR'
  },
  MXN: {
    symbol: 'MX$',
    code: 'MXN'
  },
  MYR: {
    symbol: 'RM',
    code: 'MYR'
  },
  MZN: {
    symbol: 'MTn',
    code: 'MZN'
  },
  NAD: {
    symbol: 'N$',
    code: 'NAD'
  },
  NGN: {
    symbol: '₦',
    code: 'NGN'
  },
  NIO: {
    symbol: 'C$',
    code: 'NIO'
  },
  NOK: {
    symbol: 'Nkr',
    code: 'NOK'
  },
  NPR: {
    symbol: 'NPRs',
    code: 'NPR'
  },
  NZD: {
    symbol: 'NZ$',
    code: 'NZD'
  },
  OMR: {
    symbol: 'OMR',
    code: 'OMR'
  },
  PAB: {
    symbol: 'B/.',
    code: 'PAB'
  },
  PEN: {
    symbol: 'S/.',
    code: 'PEN'
  },
  PHP: {
    symbol: '₱',
    code: 'PHP'
  },
  PKR: {
    symbol: 'PKRs',
    code: 'PKR'
  },
  PLN: {
    symbol: 'zł',
    code: 'PLN'
  },
  PYG: {
    symbol: '₲',
    code: 'PYG'
  },
  QAR: {
    symbol: 'QR',
    code: 'QAR'
  },
  RON: {
    symbol: 'RON',
    code: 'RON'
  },
  RSD: {
    symbol: 'din.',
    code: 'RSD'
  },
  RUB: {
    symbol: 'RUB',
    code: 'RUB'
  },
  RWF: {
    symbol: 'RWF',
    code: 'RWF'
  },
  SAR: {
    symbol: 'SR',
    code: 'SAR'
  },
  SDG: {
    symbol: 'SDG',
    code: 'SDG'
  },
  SEK: {
    symbol: 'Skr',
    code: 'SEK'
  },
  SGD: {
    symbol: 'S$',
    code: 'SGD'
  },
  SOS: {
    symbol: 'Ssh',
    code: 'SOS'
  },
  SYP: {
    symbol: 'SY£',
    code: 'SYP'
  },
  THB: {
    symbol: '฿',
    code: 'THB'
  },
  TND: {
    symbol: 'DT',
    code: 'TND'
  },
  TOP: {
    symbol: 'T$',
    code: 'TOP'
  },
  TRY: {
    symbol: 'TL',
    code: 'TRY'
  },
  TTD: {
    symbol: 'TT$',
    code: 'TTD'
  },
  TWD: {
    symbol: 'NT$',
    code: 'TWD'
  },
  TZS: {
    symbol: 'TSh',
    code: 'TZS'
  },
  UAH: {
    symbol: '₴',
    code: 'UAH'
  },
  UGX: {
    symbol: 'USh',
    code: 'UGX'
  },
  UYU: {
    symbol: '$U',
    code: 'UYU'
  },
  UZS: {
    symbol: 'UZS',
    code: 'UZS'
  },
  VEF: {
    symbol: 'Bs.F.',
    code: 'VEF'
  },
  VND: {
    symbol: '₫',
    code: 'VND'
  },
  XAF: {
    symbol: 'FCFA',
    code: 'XAF'
  },
  XOF: {
    symbol: 'CFA',
    code: 'XOF'
  },
  YER: {
    symbol: 'YR',
    code: 'YER'
  },
  ZAR: {
    symbol: 'R',
    code: 'ZAR'
  },
  ZMK: {
    symbol: 'ZK',
    code: 'ZMK'
  },
  ZWL: {
    symbol: 'ZWL$',
    code: 'ZWL'
  }
};
