import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledTabTwoButton,
  StyledTabWebButtonsWrapper,
  StyledTabWebTwoWrapper
} from './navigationTabsWebTwo.styled';

function NavigationTabsWebTwo({
  handleTabClick,
  activeTab
}) {
  const {t} = useTranslation();

  return (
    <StyledTabWebTwoWrapper spaceBetween>
      <StyledTabWebButtonsWrapper hasGap>
        <StyledTabTwoButton
          type="button"
          name={OPERATION_TYPE_NUMBER.DEPOSIT}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
        >
          <TabDeposit />
          {activeTab === OPERATION_TYPE_NUMBER.DEPOSIT ? (
            <span>{t(('deposit')?.toLowerCase())}</span>
          ) : null}
        </StyledTabTwoButton>
        <StyledTabTwoButton
          type="button"
          name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
          onClick={handleTabClick}
          activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
        >
          <TabWithdrawal />
          {activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL ? (
            <span>{t(('withdrawal')?.toLowerCase())}</span>
          ) : null}
        </StyledTabTwoButton>
      </StyledTabWebButtonsWrapper>
    </StyledTabWebTwoWrapper>
  );
}

NavigationTabsWebTwo.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default NavigationTabsWebTwo;
