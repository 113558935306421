import styled from 'styled-components';
import {disable, generateAlertColor} from '../../../helpers/styledHelpers';

const StyledIcon = styled.i`
  display: ${(p) => (p.display ? p.display : 'block')};
  flex-shrink: 0;
  font-weight: ${(p) => (p.fontWeight ? p.fontWeight : 'normal')};
  font-family: 'icomoon', serif;
  font-style: normal;
  text-align: center;
  cursor: pointer;

  &:before {
    content: '\\${(p) => p.theme.icons[p.icon]}';
  }
`;

const StyledIconSmall = styled(StyledIcon)`
  width: ${(p) => (p.width ? p.width : '24px')};
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  margin-right: ${(props) => props.marginRight};
  margin-left: ${(props) => props.marginLeft};
  font-size: ${(p) => (p.fontSize ? `${p.fontSize}!important` : '16px')};
  line-height: 24px;
  ${disable(0.6)};
  transition: 500ms;
  color: ${({state}) => generateAlertColor(state)};
`;

export default StyledIcon;
export {StyledIconSmall};
