import React, {createContext, useState, useEffect} from 'react';
import {ALERT_TYPES} from '../constants/common';
import {isObject} from '../helpers/helperFunction';

const AlertContext = createContext({
  alertType: null,
  alertText: null,
  success: () => {},
  warning: () => {},
  error: () => {},
  info: () => {},
  clear: () => {}
});
/* eslint-disable react/prop-types, react/jsx-no-constructed-context-values */
const AlertProvider = function({children}) {
  const [alertType, setAlertType] = useState(null);
  const [alertText, setAlertText] = useState(null);

  /**
   * Set Success message and open Alert
   * @param {string} text
   */
  const success = (text) => {
    setAlertText(text);
    setAlertType(ALERT_TYPES.SUCCESS);
  };

  /**
   * Set Warning message and open Alert
   * @param {string} text
   */
  const warning = (text) => {
    setAlertText(text);
    setAlertType(ALERT_TYPES.WARNING);
  };

  /**
   * Set Error message and open Alert
   * @param {object} body
   */
  const error = (body) => {
    let message = '';
    if (isObject(body)) {
      message = body?.PsErrorMessage || body?.Message || body?.Mesage || body?.message || 'Something went wrong';
    } else {
      message = body;
    }
    setAlertText(message);
    setAlertType(ALERT_TYPES.ERROR);
  };

  /**
   * Set Info message and open Alert
   * @param {string} text
   */
  const info = (text) => {
    setAlertText(text);
    setAlertType(ALERT_TYPES.INFO);
  };

  /**
   * Clear Alert text and close Alert
   */
  const clear = () => {
    setAlertText(null);
    setAlertType(null);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      clear();
    }, 5000);
    return () => clearTimeout(timerId);
  }, [alertType, alertText]);

  return (
    <AlertContext.Provider
      value={{
        success,
        warning,
        error,
        info,
        clear,
        alertType,
        alertText
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export {AlertProvider};
export default AlertContext;
