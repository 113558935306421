import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
// eslint-disable-next-line import/no-unresolved
import {Swiper, SwiperSlide} from 'swiper/react';
// eslint-disable-next-line import/no-unresolved
import {Navigation} from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import PropTypes from 'prop-types';
import {SimplifiedDataContext} from '../../contexts/simplifiedDataContext';
import {PaymentFormCallbacksContext} from '../../contexts/paymentFormCallbacksContext';
import {PaymentHistoryCallbacksContext} from '../../contexts/paymentHistoryCallbacksContext';
import {AccountVerificationHistoryContext} from '../../contexts/accountVerificationHistoryContext';
import {SelectedPaymentDetailsContext} from '../../contexts/selectedPaymentDetailsContext';

/* Styled Components */
import {StyledLogoWrapper, StyledSliderItem} from './slider.styled';
import {StyledPaymentHint} from '../paymentsList/templateOne/web/paymentItemWeb.styled';

function Slider({isHistory}) {
  const {t} = useTranslation();
  const simplifiedData = useContext(SimplifiedDataContext);
  const {paymentSystemId} = useContext(SelectedPaymentDetailsContext) ?? {};
  const {handlePaymentSystemDetailsPage} = useContext(isHistory ? PaymentHistoryCallbacksContext : PaymentFormCallbacksContext) ?? {};
  const {clearVerificationHistoryHandler} = useContext(AccountVerificationHistoryContext) ?? {};

  const handleSliderItemClick = (sliderItem) => {
    if (sliderItem.paymentSystemId !== paymentSystemId) {
      if (typeof clearVerificationHistoryHandler === 'function') {
        clearVerificationHistoryHandler();
      }
      handlePaymentSystemDetailsPage({
        paymentSystemId: sliderItem?.paymentSystemId,
        platformPsId: sliderItem?.platformPaymentSystemId,
        disableMethod: sliderItem?.disableMethod,
        unavailable: sliderItem?.unavailable,
        hasRedirect: sliderItem?.hasRedirect,
        hasVerifiedAccount: sliderItem?.hasVerifiedAccount
      });
    }
  };

  return (
    <Swiper
      navigation
      slidesPerView="auto"
      modules={[Navigation]}
      spaceBetween={-2}
      centeredSlidesOffset={8}
      centerInsufficientSlides
    >
      {simplifiedData?.map((sliderItem) => (
        <SwiperSlide key={sliderItem.id}>
          <StyledSliderItem
            onClick={() => handleSliderItemClick(sliderItem)}
            selectedItem={sliderItem.paymentSystemId === paymentSystemId}
          >
            <StyledLogoWrapper>
              <img
                src={sliderItem.logoPath}
                alt={sliderItem.paymentSystemName}
              />
              {sliderItem.isNew ? (
                <StyledPaymentHint>
                  {t(('new')?.toLowerCase())}
                </StyledPaymentHint>
              ) : null}
              {sliderItem.unavailable ? (
                <StyledPaymentHint
                  unavailable={sliderItem?.unavailable}
                >
                  {t(('underMaintenance')?.toLowerCase())}
                </StyledPaymentHint>
              ) : null}
            </StyledLogoWrapper>
          </StyledSliderItem>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

Slider.propTypes = {
  isHistory: PropTypes.bool
};

Slider.defaultProps = {
  isHistory: false
};

export default Slider;
