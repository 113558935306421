import React from 'react';
import PropTypes from 'prop-types';
import {ThemeProvider as StyledThemeProvider} from 'styled-components';
import {mergedTheme} from '../helpers/styled';

/* Styled Components */
import StyledGlobal from '../styledComponents/Global.styled';

function ThemeProvider({theme, children}) {
  return (
    <StyledThemeProvider theme={mergedTheme(theme)}>
      <StyledGlobal />
      {children}
    </StyledThemeProvider>
  );
}

ThemeProvider.defaultProps = {
  theme: null,
  children: ''
};

ThemeProvider.propTypes = {
  theme: PropTypes.object,
  children: PropTypes.array
};
export default ThemeProvider;
