import styled from 'styled-components';
import {flexContainer, fontStyle} from '../../helpers/styled';

const StyledSwitcherContainer = styled.div`
  ${flexContainer};
  justify-content: flex-start;
  grid-gap: 8px;
  margin-bottom: 16px;
`;

const SwitchContainer = styled.label`
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
`;

const Switcher = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 40px;
  background-color: ${({isOn, theme}) => (isOn ? theme.mainButtonBackgroundColor : `${theme.textContentColor}30`)};
  transition: .4s;
  cursor: pointer;

  &:before {
    position: absolute;
    left: ${({isOn}) => (isOn ? '17px' : '3px')};
    bottom: 2px;
    content: '';
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: ${({theme}) => theme.mainButtonTextColor};
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 25%);
    transition: left .4s;
  }
`;

const StyledSwitchLabel = styled.label`
  ${fontStyle('regular')};
  color: ${({theme}) => theme.cardTextTitleColor};
`;

export default StyledSwitcherContainer;

export {
  SwitchContainer,
  Switcher,
  StyledSwitchLabel
};
