import React, {useContext} from 'react';
import AlertContext from '../../context/alertContext';

/* Styled Components */
import StyledAlertOverlay, {StyledAlertOverlayText} from './alertMessage.styled';
import {StyledIconSmall} from '../styledComponents/icons/icon.styled';

const AlertMessage = function() {
  const {alertType, alertText} = useContext(AlertContext);
  return alertType ? (
    <StyledAlertOverlay state={alertType}>
      <StyledIconSmall
        icon={alertType}
        alt={alertType}
        state={alertType}
      />
      <StyledAlertOverlayText>{alertText}</StyledAlertOverlayText>
    </StyledAlertOverlay>
  ) : null;
};

export default AlertMessage;
