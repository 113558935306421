import styled from 'styled-components';

const StyledHideShowTab = styled.div`
  position: ${({theme}) => (theme.adminWrapper ? 'sticky' : 'fixed')};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 56px;
  padding: 0 8px;
  color: white;
  backdrop-filter: blur(5px);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  transform: translateY(${({isVisible}) => (isVisible ? '0' : '56px')});
  opacity: ${({isVisible}) => (isVisible ? '1' : '0')};
  visibility: ${({isVisible}) => (isVisible ? 'visible' : 'hidden')};
  z-index: ${({theme}) => theme.zIndex9999};
`;

export {
  StyledHideShowTab
};
