import instance from './apiService';
import Config from '../configs/mainConfig';

export const getSitePaymentSystemDescriptions = (
  setSitePaymentSystemDescriptions,
  siteId,
  paymentSystemId,
  operationType,
  language,
  setIsLoading,
  alertContext
) => {
  setIsLoading(true);
  instance.get(
    `${Config.apiURLs.getSitePaymentSystemDescriptions}/${siteId}/${paymentSystemId}/${operationType}/${language}`,
    {
      param: {
        siteId,
        paymentSystemId,
        operationType,
        language
      }
    }
  )
    .then((response) => {
      setSitePaymentSystemDescriptions(response.data);
    })
    .catch((error) => alertContext.error(error.response.data))
    .finally(() => setIsLoading(false));
};
