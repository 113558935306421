import React from 'react';
import PropTypes from 'prop-types';

/* Styled Components */
import StyledInputWrapper, {
  StyledInput
} from '../../styledComponents/input/input.styled';

function Search({searchPlaceholder, setSearchField}) {
  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  return (
    <StyledInputWrapper>
      <StyledInput placeholder={searchPlaceholder} onChange={handleChange} />
      {/* <StyledInputIconWrapper /> */}
    </StyledInputWrapper>
  );
}

Search.defaultProps = {
  searchPlaceholder: '',
  setSearchField: null
};

Search.propTypes = {
  searchPlaceholder: PropTypes.string,
  setSearchField: PropTypes.func
};

export default Search;
