import instance from './apiService';
import Config from '../configs/mainConfig';

/**
 * Retrieves transaction history for a specific payment system.
 *
 * @param {function} setHistoryData - A function to set the retrieved transaction history data.
 * @param {number} paymentSystemId - The identifier of the payment system.
 * @param {number} siteId - The site identifier.
 * @param {number} userId - The user identifier.
 * @param {number} tabId - The identifier of the tab.
 * @param {function} setIsLoading - A function to set the loading status.
 * @param {object} alertContext - The context object for displaying alerts.
 * @param {function} alertContext.error - A function to display an error alert.
 *
 * @returns {Promise} - A promise that resolves with the retrieved transaction history data
 *                    or rejects with an error message.
 *
 * @throws {Error} - If the request to the API fails, an error object is thrown.
 *
 * @example
 * const setHistoryData = (data) => {
 *   // Update UI with retrieved transaction history data
 * };
 *
 * const paymentSystemId = 3466;
 * const siteId = 1991;
 * const userId = 414023867;
 * const tabId = 2;
 * const setIsLoading = (isLoading) => {
 *   // Set loading status in the UI
 * };
 * const alertContext = {
 *   error: (errorMessage) => {
 *     // Display an error alert in the UI
 *     console.error(errorMessage);
 *   }
 * };
 *
 * getHistoryByPaymentSystem(setHistoryData, paymentSystemId, siteId, userId, tabId, setIsLoading, alertContext)
 *   .then((historyData) => {
 *     // Handle retrieved transaction history data
 *   })
 *   .catch((error) => {
 *     console.error('Failed to retrieve transaction history:', error.message);
 *   });
 */
export const getHistoryByPaymentSystem = (
  setHistoryData,
  paymentSystemId,
  siteId,
  userId,
  tabId,
  setIsLoading,
  alertContext
) => {
  setIsLoading(true);

  return instance.get(
    `${Config.apiURLs.getPaymentSystemTransactionsHistory}/${paymentSystemId}/${siteId}/${userId}/${tabId}`,
    {
      param: {
        paymentSystemId,
        siteId,
        userId,
        tabId
      }
    }
  )
    .then((response) => {
      setHistoryData(response?.data);
      return response?.data;
    })
    .catch((error) => {
      alertContext?.error(error?.response?.data);
      throw error;
    })
    .finally(() => setIsLoading(false));
};
