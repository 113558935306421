import styled from 'styled-components';

import {
  fontStyle, generateAlertBackgroundColor, generateAlertColor, MOBILE_SIZE
} from '../../helpers/styledHelpers';

const StyledAlertOverlay = styled.div`
  position: fixed;
  left: 50%;
  top: 20px;
  padding: 12px 12px 12px 8px;
  max-width: 500px;
  min-width: auto;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;
  transform: translate(-50%, 10px);
  background-color: ${(props) => props.theme.notificationsBackgroundColor};
  color: ${(props) => props.theme.notificationsColor};
  border: ${({theme}) => `
    ${theme.notificationsBorderTop} 
    ${theme.notificationsBorderRight} 
    ${theme.notificationsBorderBottom} 
    ${theme.notificationsBorderLeft}
  `};
  background-color: ${({state}) => generateAlertBackgroundColor(state)};
  border-radius: ${({theme}) => `
    ${theme.notificationsBorderTopLeftRadius} 
    ${theme.notificationsBorderTopRightRadius} 
    ${theme.notificationsBorderBottomRightRadius} 
    ${theme.notificationsBorderBottomLeftRadius}
  `};
  border-color: ${({state}) => generateAlertColor(state)};
  border-style: solid;
  z-index: ${({theme}) => theme.zIndex9999};

  i {
    width: 32px;
    height: 32px;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: ${MOBILE_SIZE}){
    text-align: center;
    z-index: 9999999999;
    padding: 12px 8px;
    top: 8px;
    
    i {
      display: none;
    }
  }
`;

const StyledAlertOverlayText = styled.p`
  width: 100%;
  ${fontStyle('regular')};
  align-self: center;
  font-size: ${(props) => props.theme.typographyFontSizeMiddle};
`;

export default StyledAlertOverlay;
export {
  StyledAlertOverlayText
};
