import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {OPERATION_TYPE_NUMBER} from '../../../../constants/paymentLists';
import {TabDeposit, TabWithdrawal} from '../../../../assets/icons';

/* Styled Components */
import {
  StyledMobileTwoButton,
  StyledTabMobileTwoWrapper
} from './navigationTabsMobileTwo.styled';

function NavigationTabsMobileTwo({
  handleTabClick,
  activeTab
}) {
  const {t} = useTranslation();

  return (
    <StyledTabMobileTwoWrapper>
      <StyledMobileTwoButton
        type="button"
        name={OPERATION_TYPE_NUMBER.DEPOSIT}
        onClick={handleTabClick}
        activeTab={activeTab === OPERATION_TYPE_NUMBER.DEPOSIT}
      >
        <TabDeposit />
        <span>{t(('deposit')?.toLowerCase())}</span>
      </StyledMobileTwoButton>
      <StyledMobileTwoButton
        type="button"
        name={OPERATION_TYPE_NUMBER.WITHDRAWAL}
        onClick={handleTabClick}
        activeTab={activeTab === OPERATION_TYPE_NUMBER.WITHDRAWAL}
      >
        <TabWithdrawal />
        <span>{t(('withdrawal')?.toLowerCase())}</span>
      </StyledMobileTwoButton>
    </StyledTabMobileTwoWrapper>
  );
}

NavigationTabsMobileTwo.propTypes = {
  handleTabClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired
};

export default NavigationTabsMobileTwo;
