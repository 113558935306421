import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import HistoryTabContentWeb from './historyTabContentWeb';
import {CODE_TYPE, HISTORY_TAB} from '../../../../constants/common';
import Slider from '../../../slider/slider';
import GenerateCode from '../../../generateCode/generateCode';

/* Styled Components*/
import {
  StyledCardHeaderTitle,
  StyledCardHeaderWrapper,
  StyledFlexContainerForPsForm,
  StyledMainFormAndInfoWrapper,
  StyledNoDataMessage,
  StyledPaymentDetailWrapper,
  StyledPaymentInfoRightSide,
  StyledPaymentName
} from '../../../paymentsForm/templateOne/web/formWeb.styled';
import {StyledMainWrapper} from '../../../paymentsList/templateOne/web/paymentItemWeb.styled';
import StyledInputWrapper, {
  StyledFormWrapper,
  StyledInput,
  StyledInputLabel
} from '../../../../styledComponents/input/input.styled';
import {
  StyledButtonWithoutBg,
  StyledFlexContainer,
  StyledLinkButton
} from '../../../../styledComponents/button/button.styled';
import {StyledIconSmall} from '../../../../styledComponents/icon/icon.styled';
import StyledTabLinkWrapper, {StyledTabButton} from '../../../../styledComponents/tab/tab.styled';
import {StyledFilterWrapper} from '../../../search/search.styled';

function HistoryWeb({
  paymentControlsData,
  historyData,
  getSelectedTab,
  navigate,
  translations,
  isCurrencySymbol,
  alertContext
}) {
  const {t} = useTranslation();
  const [selectedTab, setSelectedTab] = useState(HISTORY_TAB.NEW);
  const [historyDetailsData, setHistoryDetailsData] = useState([]);
  const [filteredHistoryData, setFilteredHistoryData] = useState([]);
  const [dataType, setDataType] = useState('');
  const [dataName, setDataName] = useState('');
  const [currency, setCurrency] = useState('');

  useEffect(() => {
    setFilteredHistoryData(historyData);
  }, [historyData]);

  useEffect(() => {
    getSelectedTab(selectedTab);
  }, [selectedTab]);

  /**
   * Handles onClick function of templates tabs
   *
   * @param {number} tab
   */
  const onTabClickHandler = (tab) => () => {
    getSelectedTab(tab);
    setSelectedTab(tab);
    setHistoryDetailsData([]);
  };

  const getSelectedHistoryDetails = (historyDetails) => {
    const sortedData = [...historyDetails].sort((a, b) => a.order - b.order);
    setHistoryDetailsData(sortedData);
  };
  const filterByAmount = (e) => {
    const inputValue = e.target.value.trim(); // Get the user input and remove leading/trailing spaces
    if (inputValue === '') {
      setFilteredHistoryData(historyData);
      return;
    }

    const filterValue = parseFloat(inputValue);

    const filteredData = historyData.filter((item) => item.amount === filterValue);

    setFilteredHistoryData(filteredData);
  };

  useEffect(() => {
    const foundData = filteredHistoryData[0]?.data.find((el) => el.dataName === CODE_TYPE.BAR_CODE
      || el.dataName === CODE_TYPE.QR_CODE);
    if (foundData) {
      setDataType(foundData.dataName === CODE_TYPE.BAR_CODE ? 'BarCode' : 'QrCode');
    }
  }, [filteredHistoryData]);

  return (
    <StyledMainWrapper scrollHidden>
      <Slider isHistory />
      <StyledFlexContainerForPsForm minHeight>
        <StyledFormWrapper key={paymentControlsData?.paymentSystemId}>
          <StyledPaymentDetailWrapper>
            <StyledButtonWithoutBg
              isPositionLeft
              type="button"
              onClick={() => navigate('/paymentsList')}
            >
              <StyledIconSmall icon="arrowLeft" fontSize="11px" />
            </StyledButtonWithoutBg>
            <StyledFlexContainer fullWidth>
              <StyledPaymentName>
                {dataType}
                {' '}
                {t(('History')?.toLowerCase())}
              </StyledPaymentName>
            </StyledFlexContainer>
          </StyledPaymentDetailWrapper>
          <StyledTabLinkWrapper>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.NEW}
              onClick={onTabClickHandler(HISTORY_TAB.NEW)}
            >
              {t(('new')?.toLowerCase())}
            </StyledTabButton>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.PAID}
              onClick={onTabClickHandler(HISTORY_TAB.PAID)}
            >
              {t(('paid')?.toLowerCase())}
            </StyledTabButton>
            <StyledTabButton
              type="button"
              isActive={selectedTab === HISTORY_TAB.EXPIRED}
              onClick={onTabClickHandler(HISTORY_TAB.EXPIRED)}
            >
              {t(('expired')?.toLowerCase())}
            </StyledTabButton>
          </StyledTabLinkWrapper>
          <StyledMainFormAndInfoWrapper minHeight>
            {selectedTab === HISTORY_TAB.NEW && historyData?.length ? (
              <StyledFilterWrapper>
                <StyledInputWrapper>
                  <StyledInputLabel>
                    {translations.Amount}
                  </StyledInputLabel>
                  <StyledInput
                    type="text"
                    name="search-form"
                    id="search-form"
                    className="search-input"
                    placeholder={t(('searchByAmount')?.toLowerCase())}
                    onChange={(e) => filterByAmount(e)}
                  />
                </StyledInputWrapper>
              </StyledFilterWrapper>
            ) : null}
            {filteredHistoryData?.length ? (
              <HistoryTabContentWeb
                historyData={filteredHistoryData}
                getSelectedHistoryDetails={getSelectedHistoryDetails}
                isCurrencySymbol={isCurrencySymbol}
                setCurrency={setCurrency}
              />
            ) : (
              <StyledNoDataMessage>
                {selectedTab === HISTORY_TAB.NEW && t('No New Barcode created')}
                {selectedTab === HISTORY_TAB.PAID && t('No Paid barcode found')}
                {selectedTab === HISTORY_TAB.EXPIRED && t('No Expired barcode found')}
              </StyledNoDataMessage>
            )}
            <StyledLinkButton
              centerAligned
              onClick={() => navigate('/paymentsList/:id')}
            >
              {t(('backToForm')?.toLowerCase())}
            </StyledLinkButton>
          </StyledMainFormAndInfoWrapper>
        </StyledFormWrapper>
        <StyledPaymentInfoRightSide>
          {historyDetailsData?.length ? (
            <>
              <StyledCardHeaderWrapper>
                <StyledCardHeaderTitle>
                  {t((dataName)?.toLowerCase())}
                </StyledCardHeaderTitle>
              </StyledCardHeaderWrapper>
              <GenerateCode
                setDataName={setDataName}
                codeDetailsData={historyDetailsData}
                translations={translations}
                alertContext={alertContext}
                currency={currency}
                isCurrencySymbol={isCurrencySymbol}
              />
            </>
          ) : null }
        </StyledPaymentInfoRightSide>
      </StyledFlexContainerForPsForm>
    </StyledMainWrapper>
  );
}

HistoryWeb.defaultProps = {
  paymentControlsData: null,
  historyData: null,
  getSelectedTab: null,
  navigate: null,
  translations: null
};

HistoryWeb.propTypes = {
  paymentControlsData: PropTypes.object,
  historyData: PropTypes.array,
  getSelectedTab: PropTypes.func,
  navigate: PropTypes.func,
  translations: PropTypes.object,
  isCurrencySymbol: PropTypes.bool.isRequired,
  alertContext: PropTypes.object.isRequired
};
export default HistoryWeb;
