import styled, {css} from 'styled-components';
import {
  flexColumnContainer,
  flexContainer,
  fontStyle,
  generateStatusMessageFieldColor,
  getBorder,
  getBorderRadius,
  truncate
} from '../../helpers/styled';

const StyledInputWrapper = styled.div`
  ${({isNotRelative}) => !isNotRelative && css`
    position: relative;
  `}
`;

const StyledInputLabel = styled.label`
  display: inline-block;
  width: auto;
  margin-bottom: 4px;
  ${fontStyle('semiBold')};
  font-size: ${({theme}) => theme.typographyFontSizeSmall};
  color: ${({theme}) => theme.inputLabelColor};
`;

const StyledCheckboxInputLabel = styled(StyledInputLabel)`
  margin-bottom: 0;
  ${fontStyle('regular')};
  color: ${({theme}) => theme.cardTextTitleColor};

  a {
    font-weight:  ${({theme}) => theme.typographyFontWeightMedium};;
    font-size: ${(props) => props.theme.typographyFontSizeMiddle};
    text-decoration: underline;
    text-transform: capitalize;
    color: ${(props) => props.theme.textButtonBackgroundColor};
  }
`;

const StyledCheckboxInputWrapper = styled.div`
  ${flexContainer()};
  align-items: flex-start;
  grid-gap: 4px;
`;

const StyledCheckboxWrapper = styled.div`
  ${flexContainer()};
  width: 24px;
  height: 24px;
`;

const StyledCheckbox = styled.input`
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid ${({theme}) => `${theme.textContentColor}`};
  border-radius: 4px;
  outline: none;
  cursor: pointer;

  &:checked {
    border: 0;
    background-color: ${({theme}) => theme.mainButtonBackgroundColor};
  }

  &:checked::after {
    content: "";
    position: absolute;
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border-style: solid;
    border-color: ${({theme}) => theme.mainButtonTextColor};;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
`;

const StyledInput = styled.input`
  width: 100%;
  height: 36px;
  padding: 0 ${({hasIcon}) => (hasIcon ? '36px' : '12px')} 0 12px;
  border: ${(props) => (props.theme.inputHasBorder
    ? `${props.theme.inputBorderWidth} solid ${props.theme.inputBorderColor}`
    : props.theme.defaultInputBorderWidth)};
  border-top: ${({theme}) => theme.inputBorderTop};
  border-bottom: ${({theme}) => theme.inputBorderBottom};
  border-left: ${({theme}) => theme.inputBorderLeft};
  border-right: ${({theme}) => theme.inputBorderRight};
  border-radius: ${(props) => getBorderRadius(props, 'input')}; // Handles border-radius for all corners
  background-color: ${({theme}) => theme.inputBackgroundColor};
  color: ${({theme}) => theme.inputTextColor};
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  &::placeholder {
    ${fontStyle('regular')};
    color: ${({theme}) => `${theme.inputTextColor}70`};
  }

  &:-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus,
  :-webkit-autofill:active {
    color: ${({theme}) => theme.inputTextColor};
    -webkit-text-fill-color: ${({theme}) => theme.inputTextColor};
  }

  &:hover, &:focus {
    border: 1px solid ${(props) => props.theme.mainButtonBackgroundColor};
  }

  ${(props) => props.hasError && css`
    border: 1px solid ${() => props.theme.errorColor};;

    &:hover, :focus {
      border: 1px solid ${() => props.theme.errorColor};;
    }
  `}
`;

const StyledInputError = styled.div`
  padding-top: 4px;
  ${fontStyle('regular')};
  font-size: ${(props) => props.theme.typographyFontSizeSmall} ;
  color: ${({theme}) => theme.errorColor};
`;

const StyledInputContainer = styled.div`
  position: relative;
`;

const StyledInputIconWrapper = styled.span`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  ${fontStyle('semiBold')};
  font-size: ${(props) => props.theme.typographyFontSizeSmall};
  color: ${({theme}) => `${theme.inputTextColor}70`};
`;

const StyledFormWrapper = styled.div`
  width: 60%;
  ${flexColumnContainer()};
  background-color:  ${({theme}) => theme.generalCardsColor};
  position: relative;
`;

const StyledWrapper = styled.div`
  text-align: center;
`;

const StyledStatusMessageWrapper = styled.div`
  width: auto;
  padding: 8px;
  ${flexContainer()};
  display: inline-flex;
  grid-gap: 8px;
  border-radius: 16px;
  border: 1px solid ${({state}) => generateStatusMessageFieldColor(state, 1)};
  background-color: ${({state}) => generateStatusMessageFieldColor(state, 0.10)};
  color:  ${({theme}) => theme.generalBackgroundColor};

  i {
    color: ${({state}) => generateStatusMessageFieldColor(state, 1)};
  }
`;
const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) => props.largsSize && css`
    width: 32px;
    min-width: 32px;
    height: 32px;
  `}
`;

const StyledMessageText = styled.p`
  ${fontStyle('regular')};
  line-height: 24px;
  text-align: left;
  color: ${({theme}) => theme.generalColorNeutralMinimum};
`;
const PayPalInputStyles = {
  input: {
    'font-size': '16px',
    'font-family': 'monospace',
    'font-weight': 'lighter',
    color: 'blue'
  },
  '.invalid': {
    color: 'purple'
  },
  ':hover': {
    color: 'orange'
  },
  '.purple': {
    color: 'purple'
  }
};

const StyledUploadDocumentWrapper = styled.div`
  ${flexColumnContainer()};
  grid-gap: 24px;
`;

const StyledUploadDocumentLabel = styled.label`
  width: 100%;
  height: 36px;
  ${flexContainer()};
  padding: 0 12px;
  border: 1px dashed ${({theme}) => `${theme.textContentColor}30`};
  border-radius: ${(props) => getBorderRadius(props, 'input')}; // Handles border-radius for all corners
  ${fontStyle('semiBold')};
  color: ${({theme}) => theme.mainButtonBackgroundColor};
  cursor: pointer;
`;

const StyledUploadedDocumentList = styled.ul`
  ${flexColumnContainer()};
  grid-gap: 24px;
  list-style-type: none;
`;

const StyledUploadedDocumentListItem = styled.li`
  height: 36px;
  ${flexContainer()};
  justify-content: flex-start;
  grid-gap: 8px;
  padding: 0 12px;
  border: 1px solid ${({theme}) => `${theme.textContentColor}30`};
  border-radius: ${(props) => getBorderRadius(props, 'input')}; // Handles border-radius for all corners
  
  svg path {
    fill: ${({theme}) => theme.generalIconsColor};
  }
`;

const StyledUploadedDocumentName = styled.p`
  flex: 1;
  ${fontStyle('regular')};
  color: ${(props) => props.theme.cardTextTitleColor};
  ${truncate()};
`;

export default StyledInputWrapper;
export {
  StyledInputLabel,
  StyledInput,
  StyledInputError,
  StyledFormWrapper,
  StyledStatusMessageWrapper,
  StyledMessageText,
  StyledIconWrapper,
  StyledWrapper,
  PayPalInputStyles,
  StyledCheckbox,
  StyledCheckboxInputWrapper,
  StyledCheckboxWrapper,
  StyledCheckboxInputLabel,
  StyledUploadDocumentWrapper,
  StyledUploadDocumentLabel,
  StyledUploadedDocumentList,
  StyledUploadedDocumentListItem,
  StyledUploadedDocumentName,
  StyledInputIconWrapper,
  StyledInputContainer
};
