import styled from 'styled-components';
import {StyledCycle, StyledHistoryListItemInfoWrapper, StyledHistoryListItemTitle} from '../web/historyWeb.styled';

const StyledHistoryListItemInfoWrapperMobile = styled(StyledHistoryListItemInfoWrapper)`
  flex-direction: column;
`;

const StyledCycleMobile = styled(StyledCycle)`
  display: none
`;

const StyledHistoryListItemTitleMobile = styled(StyledHistoryListItemTitle)`
  width: 100%;
  justify-content: space-between;
`;

export {
  StyledHistoryListItemInfoWrapperMobile,
  StyledHistoryListItemTitleMobile,
  StyledCycleMobile
};
