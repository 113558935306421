export const theme = {
  // notifications
  notificationsColor: '#fff',
  notificationsBackgroundColor: '#2b2b2b',
  notificationsBorder: 'true',
  notificationsBorderTop: '1px',
  notificationsBorderLeft: '1px',
  notificationsBorderRight: '1px',
  notificationsBorderBottom: '1px',
  notificationsBorderColor: '#2b2b2b',
  notificationsBorderTopLeftRadius: '16px',
  notificationsBorderTopRightRadius: '16px',
  notificationsBorderBottomRightRadius: '16px',
  notificationsBorderBottomLeftRadius: '16px',
  notificationsPosition: 'topMiddle',

  // typography
  typographyFontSizeMiddle: '14px',
  typographyFontSizeSmall: '10px',
  typographyFontWeightMedium: 400,
  typographyFontWeightRegular: 500,
  typographyFontWeightSemiBold: 600,
  typographyFontFamilyBase: 'Inter, sans-serif',
  // contentTextColor
  contentTextColor: '#a7a7a7',
  // hoverMotion
  zIndex9999: '9999',

  errorColor: '#CD2B31',
  successColor: '#4BA031',
  warningColor: '#FFAE2B',
  infoColor: '#006ADC',

  icons: {
    globeIcon: 'e900',
    videoIcon: 'e901',
    search: 'e902',
    processingTimeNotInstant: 'e903',
    processingTimeInstant: 'e904',
    apple: 'e905',
    arrowLeft: 'e906',
    arrowRight: 'e907',
    arrowDown: 'e908',
    arrowUp: 'e909',
    close: 'e90a',
    starFull: 'e90b',
    starBorder: 'e90c',
    warning: 'e90d',
    info: 'e90e',
    error: 'e90f',
    success: 'e910',
    download: 'e911'
  }
};
